// Extra requirements functions
import { filterObjectByFunction, isNullOrUndefined } from "../community-hats-js-library/utils/generalFunctions";
import { getATOInsideSetFields, getATOInsideSetStatus, getKeysOfATOSet, refreshATObject, refreshATOInsideSet, setStoredValue } from "../hooks/StoreHooks";
import * as locCon from "../LocalConstants";
import * as libCon from "../community-hats-js-library/Constants"


export const getPendingExtraRequests = async () => {
    let status = await Promise.all(locCon.EXTRA_REQUESTS_TO_CHECK.map(async r => await getStatusForExtraRequest(r)))
    let pending = locCon.EXTRA_REQUESTS_TO_CHECK.filter((_, i) => status[i] === locCon.EXTRA_REQUEST_STATUS_PENDING)
    return pending
}

export const getStatusOfNewPositionForSensorPlacements = async () => {

    let positionsIds = getKeysOfATOSet()

    // Refreshes all sensor placements
    await Promise.all(positionsIds.map(async posId => await refreshATOInsideSet(posId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT)))

    // Checks if the current position is empty
    let sensorPlacements = positionsIds.map(posId => getATOInsideSetFields(posId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT))
    let sensorPlacementStatus = positionsIds.map(posId => getATOInsideSetStatus(posId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT))

    let missingPosition = sensorPlacements.map((sp, i) => (!isNullOrUndefined(sp) && sp[libCon.ATF_ACTIVE] === libCon.YES) && (sensorPlacementStatus[i] !== locCon.ATO_UP_TO_DATE || !(libCon.ATF_POSITION in sp) || sp[libCon.ATF_POSITION_CHECKED] !== libCon.YES))

    // Updates the missing positions

    let missingPositionIds = Object.fromEntries(positionsIds.map((key, i) => [key, missingPosition[i]]));

    // Sets the store
    // Updates the vector
    let missingPositionIdsVector = Object.keys(filterObjectByFunction(missingPositionIds, (v) => v === true))
    missingPositionIdsVector.map(posId => setStoredValue(locCon.getUpdateSensorPositionCheckBoxId(posId), false))
    setStoredValue(locCon.EXTRA_REQUEST_UPDATE_SENSOR_POSITION_MISSING_IDS, missingPositionIdsVector)

    return missingPositionIds

}

export const getStatusForExtraRequest = async (extraRequest) => {

    switch (extraRequest) {
        case locCon.EXTRA_REQUEST_UPDATE_SENSOR_POSITION:

            let missingDict = await getStatusOfNewPositionForSensorPlacements()

            if (Object.values(missingDict).some(mp => mp === true))
                return locCon.EXTRA_REQUEST_STATUS_PENDING
            else
                return locCon.EXTRA_REQUEST_STATUS_COMPLETE
        case locCon.EXTRA_REQUEST_UPDATE_MISSING_SEWA_ID:

            // Updates the participant object
            await refreshATObject(locCon.AT_OBJECT_PARTICIPANT)

            // Always return complete. It mmight break something if the SEWA ID becommes null for some reason
            return locCon.EXTRA_REQUEST_STATUS_COMPLETE

        default:
            throw new Error(`Invalid extra request status: ${extraRequest}`);
    }

}