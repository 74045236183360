import React, { useEffect, useState } from 'react'
import { RefLanguage } from '../hooks/LanguageHooks';
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
// import * as genFun from '../community-hats-js-library/utils/generalFunctions';
import Header from '../components/Header';
import CustomText from '../elements/CustomText';
import CustomButton from '../elements/CustomButton';
import { deletePositionToATOSet, getATOInsideSetField, getKeysOfATOSet, RefStoredBooleanValue, setMultipleValues, setStoredValue, StateStoredValue } from '../hooks/StoreHooks';
import CheckboxGroup from '../elements/CheckboxGroup';
import { EndAndRemovePlacementInsideSetButton, SyncATOButton } from '../elements/SyncATOButton';
import { SimpleStoreCopySelectionField } from '../elements/SimpleStoreCopySelectionField';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import CompletionStatusText from '../elements/CompletionStatusText';
import { SimpleCopyField } from '../elements/SimpleStoreCopyField';

function RemoveSensor() {

    const i18n = RefLanguage()



    // Local checkboxes
    const isPlacementRemoved = RefStoredBooleanValue(locCon.RS_END_AND_REMOVE_SENSOR_PLACEMENT)
    const isSensorRemoved = RefStoredBooleanValue(locCon.RS_REMOVE_SENSOR)


    const [positionId, setPositionId] = StateStoredValue(locCon.RS_SELECTED_POSITION_ID)
    const [serial, setSerial] = StateStoredValue(locCon.RS_SELECTED_SERIAL)
    const [brand, setBrand] = StateStoredValue(locCon.RS_SELECTED_BRAND)


    const [options, setOptions] = useState([])
    const [optionsTextDict, setOptionsTextDict] = useState({})


    const localSetPositionId = (newPositionId) => {


        if (positionId !== newPositionId)
            restartProcess()

        setPositionId(newPositionId)

    }

    useEffect(() => {

        const keys = getKeysOfATOSet()

        let newOptions = [...keys]
        let newOPtionTextDict = Object.fromEntries(keys.map(pId => [pId, getATOInsideSetField(pId, locCon.AT_OBJECT_SENSOR, libCon.ATF_SERIAL)]))
        if (!isNullOrUndefined(positionId) && !newOptions.includes(positionId)) {
            newOptions.push(positionId)
            newOPtionTextDict[positionId] = serial
        }

        setOptions(newOptions)
        setOptionsTextDict(newOPtionTextDict)

    }, [positionId, serial])

    useEffect(() => {

        const keys = getKeysOfATOSet()

        if (keys.includes(positionId)) {
            setSerial(getATOInsideSetField(positionId, locCon.AT_OBJECT_SENSOR, libCon.ATF_SERIAL))
            setBrand(getATOInsideSetField(positionId, locCon.AT_OBJECT_SENSOR, libCon.ATF_BRAND))

        }

    }, [positionId, setSerial, setBrand])



    const allCheckboxes = [locCon.RS_COLLECT_DATA,
    locCon.RS_END_AND_REMOVE_SENSOR_PLACEMENT,
    locCon.RS_REMOVE_SENSOR,
    locCon.RS_UPDATE_BUNDLE_TO_DATABASE,
    locCon.RS_REMOVE_SENSOR_FROM_APP]



    const restartProcess = () => {

        let checkBoxValues = {
            [locCon.RS_SELECTED_POSITION_ID]: null,
            [locCon.RS_SELECTED_SERIAL]: null,
            [locCon.RS_SELECTED_BRAND]: null
        }

        allCheckboxes.forEach(k => checkBoxValues[k] = false)


        setMultipleValues(checkBoxValues)

    }


    const removeSensor = () => {
        if (!isNullOrUndefined(positionId)) {
            deletePositionToATOSet(positionId)
            setStoredValue(locCon.RS_REMOVE_SENSOR, true)
        }

    }


    return (
        <div className="mainAppContainer">
            <div className='pageContainer'>

                <Header long={false} />
                <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("removeSensor")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsRemoveSensor")}</CustomText>

                <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartProcess")}</CustomButton>

                <div className='horizontalLine' />

                <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("selectSensor")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToSelectSensor")}</CustomText>

                <SimpleStoreCopySelectionField title={i18n.t("sensor")} value={positionId} setValue={localSetPositionId} options={options} optionsTextDict={optionsTextDict} enableCopy={true} />



                <div className='horizontalLine' />

                {/* Collect Data and  Check bundle*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("collectAndCheckSensor")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("collectAndCheckSensorText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.RS_COLLECT_DATA, [libCon.TEXT]: i18n.t("collectSensorData") }} />


                <div className='horizontalLine' />

                {/* Remove Sensor placement to Database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("endSensorPalcement")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("endSensorPalcementText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.RS_END_AND_REMOVE_SENSOR_PLACEMENT,
                    [libCon.TEXT]: i18n.t("endSensorPlacement"),
                    [libCon.CONTAINER]:
                        isPlacementRemoved ?
                            <div className='verticalSection'><CustomText type={locCon.SUCCESS_TEXT}>{i18n.t("placementRemovedSuccesfully")}</CustomText></div>
                            : isNullOrUndefined(positionId)
                                ? <div className='verticalSection'><CustomText type={locCon.DANGER_TEXT}>{i18n.t("pleaseSelectASensor")}</CustomText></div>
                                : <EndAndRemovePlacementInsideSetButton positionId={positionId} placementATOid={locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT} onClickCleanup={(val) => { setStoredValue(locCon.RS_END_AND_REMOVE_SENSOR_PLACEMENT, val); }} forceSuccessText={isPlacementRemoved} />
                }} />
                <div className="horizontalLine" />


                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("removeSensorFromBundle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("removeSensorFromBundleText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.RS_REMOVE_SENSOR,
                    [libCon.TEXT]: i18n.t("removeSensor"),
                    [libCon.CONTAINER]: isSensorRemoved ?
                        <div className='verticalSection'><CustomText type={locCon.SUCCESS_TEXT}>{i18n.t("sensorRemovedSuccesfully")}</CustomText></div>
                        : isNullOrUndefined(positionId)
                            ? <div className='verticalSection'><CustomText type={locCon.DANGER_TEXT}>{i18n.t("pleaseSelectASensor")}</CustomText></div>
                            : <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => removeSensor()}>{i18n.t("removeSensor")}</CustomButton>
                }} />




                <div className="horizontalLine" />

                {/* Add / Sync Bundle to database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("updateBundle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("updateBundleText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.RS_UPDATE_BUNDLE_TO_DATABASE,
                    [libCon.TEXT]: i18n.t("updateBundle"),
                    [libCon.CONTAINER]: <SyncATOButton checkDependencies={false} atoId={locCon.AT_OBJECT_BUNDLE} atoBehavior={libCon.ATO_BEHAVIOR_UPDATE_CREATE} onClickCleanup={(val) => setStoredValue(locCon.RS_UPDATE_BUNDLE_TO_DATABASE, val)} />
                }} />

                <div className="horizontalLine" />

                {/* Remove sensor from the app*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("removeSensorFromApp")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("removeSensorFromAppText")}</CustomText>
                <CheckboxGroup mainCheckbox={{
                    [libCon.ID]: locCon.RS_REMOVE_SENSOR_FROM_APP,
                    [libCon.TEXT]: i18n.t("removeSensorFromApp"),
                    [libCon.CONTAINER]: <div className='verticalSection'>
                        <SimpleCopyField title={i18n.t("serial")} value={serial} />
                        <SimpleCopyField title={i18n.t("brand")} value={brand} />
                    </div>
                }}
                />

                <div className="horizontalLine" />


                <CompletionStatusText checkBoxIds={allCheckboxes} />

                <div className="horizontalLine" />

            </div>
        </div>
    );
}

export default RemoveSensor