import * as libCon from "../Constants.js"
import axios from "axios";
import { formatISOCustomShort } from "./dateFunctions.js";

export const buildFitbitURL = (clientID) => {
  return (`https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=${clientID}&expires_in=31536000&redirect_uri=https://dev-redirect.community-hats.net&scope=activity%20heartrate%20sleep%20temperature%20settings%20profile`)
}

export const testFitbitApi = async (requestId, token) => {


  const link = `https://api.fitbit.com/1/user/${requestId}/activities/heart/date/2024-01-01/1d/1min.json`;


  try {
    const response = await axios.get(link, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    if (response.status === 200)
      return libCon.OK

    else
      return libCon.UNOTHORIZED_ERROR


  } catch (error) {

    if (error.message === 'Network Error')
      return libCon.NETWORK_ERROR

    return libCon.ERROR

  }

}


export const getLastSync = async (requestId, token) => {


  const link = `https://api.fitbit.com/1/user/${requestId}/devices.json`;


  try {
    const response = await axios.get(link, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });


    if (response.status === 200) {
      let data = response.data

      // Removes Phone
      data = data.filter(d => d['deviceVersion'] !== 'MobileTrack')

      if (data.length > 0 && "lastSyncTime" in data[0]) {

        const lastSynched = data[0]["lastSyncTime"];

        if (isNaN(new Date(lastSynched)))
          return [libCon.OK, null]

        return [libCon.OK, lastSynched]

      }
      else {

        return [libCon.NOT_FOUND_ERROR, null]
      }


    }

  } catch (error) {

    console.log(error)
    if (error.message === 'Network request failed')
      return ([libCon.NETWORK_ERROR, null])

    return ([libCon.ERROR, null])

  }

  return [libCon.NOT_FOUND_ERROR, null]

}


export const getLatestHeartRateTimestamp = async (requestId, token, lastSynched) => {


  if (typeof lastSynched === 'string')
    lastSynched = new Date(lastSynched)

  let dateString = formatISOCustomShort(lastSynched)

  let link = `https://api.fitbit.com/1/user/${requestId}/activities/heart/date/${dateString}/1d/1min.json`


  try {
    const response = await axios.get(link, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });


    if (response.status === 200) {
      let data = response.data



      let recentHeartRate = null
      if ('activities-heart-intraday' in data) {
        let heartRateRecords = data['activities-heart-intraday']['dataset']
        if (heartRateRecords.length > 0)
          recentHeartRate = `${dateString} ${heartRateRecords[heartRateRecords.length - 1]["time"]}`
      }

      return [libCon.OK, recentHeartRate]

    }

  } catch (error) {

    console.log(error)
    if (error.message === 'Network request failed')
      return ([libCon.NETWORK_ERROR, null])

    return ([libCon.ERROR, null])

  }

  return [libCon.NOT_FOUND_ERROR, null]
}



export const getIntraHearetRate = async (requestId, token, date, minuteFrequency = 5) => {


  if (typeof date === 'string')
    date = new Date(date)

  let dateString = formatISOCustomShort(date)

  let link = `https://api.fitbit.com/1/user/${requestId}/activities/heart/date/${dateString}/1d/${minuteFrequency}min.json`


  try {
    const response = await axios.get(link, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });


    if (response.status === 200) {
      let data = response.data


      if ('activities-heart-intraday' in data) {
        let heartRateRecords = data['activities-heart-intraday']['dataset']
        return [libCon.OK, heartRateRecords]
      }

      return [libCon.OK, []]

    }

  } catch (error) {

    console.log(error)
    if (error.message === 'Network request failed')
      return ([libCon.NETWORK_ERROR, null])

    return ([libCon.ERROR, null])

  }

  return [libCon.NOT_FOUND_ERROR, null]
}

export const getHeartRateCoverageByHour = async (requestId, token, date, minuteFrequency = 5) => {

  let [response, intraHeartRate] = await getIntraHearetRate(requestId, token, date, minuteFrequency)

  if (response !== libCon.OK)
    return [response, null]

  const hourlyCounts = intraHeartRate.reduce((acc, entry) => {
    const hour = parseInt(entry.time.split(':')[0]); // Extract the hour part (e.g., '00', '01')
    acc[hour] = (acc[hour] || 0) + 1; // Increment count for that hour
    return acc;
  }, {});

  // Gets the percentage
  const percentages = Object.fromEntries([...Array(24)].map((_, i) => {
    const count = i in hourlyCounts ? hourlyCounts[i] : 0;
    const percentage = (count / (60 / minuteFrequency));
    return [i, percentage];
  }))

  return [libCon.OK, percentages]


}