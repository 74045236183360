import * as libCon from "../community-hats-js-library/Constants"
import Airtable from "airtable";
import { getCurrentI18n } from "../hooks/LanguageHooks";
import { isNullOrUndefined, isNullOrUndefinedOrEmptyOrMissing } from '../community-hats-js-library/utils/generalFunctions';





export const syncRecord = async ({ tableId, behavior, id, fields, searchField = null, searchValue = null }) => {


    // Airtable
    const at_base = new Airtable({ apiKey: await libCon.CONFIG(libCon.AT_API_KEY) }).base(await libCon.CONFIG(libCon.AT_APP_ID));

    const i18n = getCurrentI18n()

    let formula = ""
    if (!isNullOrUndefinedOrEmptyOrMissing(searchField)) {


        let searchExpressions = searchField.map((f, i) => `{${f}} = '${searchValue[i]}'`)
        formula = "AND(" + searchExpressions.join(", ") + ")"

    }

    try {

        let record, records, found


        switch (behavior) {
            case libCon.ATO_BEHAVIOR_PULL:

                if (!isNullOrUndefinedOrEmptyOrMissing(searchField) && searchValue.every(val => !isNullOrUndefinedOrEmptyOrMissing(val))) {
                    records = await at_base(tableId).select({
                        filterByFormula: formula
                    }).all();

                    if (records.length === 0)
                        return [libCon.NOT_FOUND_ERROR, i18n.t("recordNotFound"), null, null]

                    record = records[0]
                }
                else if (!isNullOrUndefined(id)) {

                    record = await at_base(tableId).find(id)
                }
                else
                    throw new Error(i18n.t("idAndSearchFieldCantBeNull"));


                return [libCon.OK, i18n.t("recordSynched"), record.id, record.fields]

            case libCon.ATO_BEHAVIOR_CREATE:

                record = await at_base(tableId).create(fields)

                return [libCon.OK, i18n.t("recordInserted"), record.id, record.fields]

            case libCon.ATO_BEHAVIOR_UPDATE:

                if (!isNullOrUndefined(id)) {
                    record = await at_base(tableId).update(id, fields)
                }
                else if (!isNullOrUndefinedOrEmptyOrMissing(searchField) && searchValue.every(val => !isNullOrUndefinedOrEmptyOrMissing(val))) {
                    records = await at_base(tableId).select({
                        filterByFormula: formula
                    }).all();


                    records = await Promise.all(records.map(async r => await at_base(tableId).update(r.id, fields)))

                    if (records.length === 0)
                        return [libCon.NOT_FOUND_ERROR, i18n.t("recordNotFound"), null, null]

                    record = records[0]

                }
                else
                    throw new Error(i18n.t("idAndSearchFieldCantBeNull"));


                return [libCon.OK, i18n.t("recordUpdated"), record.id, record.fields]

            case libCon.ATO_BEHAVIOR_UPDATE_CREATE:


                found = false
                if (!isNullOrUndefinedOrEmptyOrMissing(searchField) && searchValue.every(val => !isNullOrUndefinedOrEmptyOrMissing(val))) {
                    records = await at_base(tableId).select({
                        filterByFormula: formula
                    }).all();

                    records = await Promise.all(records.map(async r => await at_base(tableId).update(r.id, fields)))

                    // Found the recrod
                    if (records.length > 0) {
                        found = true
                        record = records[0]

                    }


                }
                if (!found && !isNullOrUndefined(id)) {
                    record = await at_base(tableId).update(id, fields)
                    found = true
                }
                if (!found) {
                    record = await at_base(tableId).create(fields)
                }

                return [libCon.OK, found ? i18n.t("recordUpdated") : i18n.t("recordInserted"), record.id, record.fields]

            case libCon.ATO_BEHAVIOR_PULL_CREATE:


                found = false
                if (!isNullOrUndefinedOrEmptyOrMissing(searchField) && searchValue.every(val => !isNullOrUndefinedOrEmptyOrMissing(val))) {
                    records = await at_base(tableId).select({
                        filterByFormula: formula
                    }).all();


                    // Found the recrod
                    if (records.length > 0) {
                        found = true
                        record = records[0]
                    }


                }
                if (!found && !isNullOrUndefined(id)) {
                    record = await at_base(tableId).find(id)
                    found = true
                }
                if (!found) {
                    record = await at_base(tableId).create(fields)
                }

                return [libCon.OK, found ? i18n.t("recordSynched") : i18n.t("recordInserted"), record.id, record.fields]


            default:
                throw new Error(i18n.t("behaviorNotRecognized"));
        }
    }
    catch (error) {

        console.log(error)
        if (error.message === 'Network request failed')
            return [libCon.NETWORK_ERROR, i18n.t("networkError"), null, null]


        return [libCon.AIRTABLE_ERROR, error.message, null, null]


    }


}


