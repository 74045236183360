const CONFIG = {
  "metadata": {
    "version": "2025.03.11"
  },
  "structure": [
    {
      "id": "strategies",
      "type": "multiple_choice_answer",
      "title": "Strategies for Heat",
      "infoText": "Which of the following strategies does the participant use when it gets hot? Select all that apply",
      "options": [
        "Turn on air conditioning",
        "Turn on a fan",
        "Turn on an air cooler",
        "Open windows or doors",
        "Close windows or doors",
        "Drink more water, lime water, buttermilk, aam panna, etc.",
        "Splash water on the face or skin",
        "Move physical activity to cooler times of the day",
        "Take more frequent breaks when working",
        "Change or remove clothes"
      ],
      "include_na": false,
      "translation": {
        "gu": {
          "title": "ગરમી માટે વ્યૂહરચના",
          "infoText": "જયારે વધારે ગરમી હોય ત્યારે બહેનો નીચે આપેલ વિકલ્પમાંથી કઈ પધ્ધ્તી નો વધારે ઉપયોગ કરે છે ?  જે લાગુ પડે તે લખો ",
          "options": [
            "એર કન્ડિશનિંગ ચાલુ કરો",
            "પંખો ચાલુ કરો",
            "કૂલર ચાલુ કરો",
            "બારીઓ  અથવા દરવાજા ખોલો",
            "બારીઓ  અથવા દરવાજા બંધ કરો",
            "વધારે પાણી  પીવુ, લીબુ પાણી, છાસ, આમ પન્ના વગેરે પીવું",
            "મોઢા ઉપર અથવા ત્વચા પર પાણી છાંટવું",
            "શારીરિક પ્રવૃતિને દિવસના ઠંડા સમય માટે ખસેડવું",
            "કામ કરતી વખતે વધારે વખત વારંવાર વિરામ લો",
            "કપડા બદલીએ "
          ]
        },
        "hi": {
          "title": "गर्मी के लिए योजनाएँ ",
          "infoText": "गर्मी बढ़ने पर बहन निम्नलिखित में से कौन से तरीके का उपयोग करती है? जो भी लागू हो उसे चुनें।",
          "options": [
            "AC चालू करना",
            "पंखा चालू करना",
            "कूलर चालू करना",
            "खिड़कियाँ-दरवाज़े खोलना",
            "खिड़कियाँ-दरवाज़े बंद करना",
            "पाना, नींबू-पानी, छास, आम पन्ना इत्याधिक ज्यादा पीना",
            "चहरे या त्वचा पर पानी छिड़कना",
            "शारीरिक गतिविधी को दिन के ठंडे समय में करना",
            "काम करते समय अधिक बार ब्रेक लेना",
            "कपड़े बदलना या हटाना"
          ]
        },
        "es": {
          "title": "Estrategias para el calor",
          "infoText": "¿Cuáles de las siguientes estrategias utiliza la participante cuando hace calor? Seleccione todas las que correspondan.",
          "options": [
            "Encender el aire acondicionado",
            "Encender un ventilador",
            "Encender un enfriador de aire",
            "Abrir ventanas o puertas",
            "Cerrar ventanas o puertas",
            "Beber más agua, agua de limón, suero de leche, aam panna, etc.",
            "Echar agua en la cara o en la piel",
            "Mover la actividad física a las horas más frescas del día",
            "Tomar descansos más frecuentes mientras trabaja",
            "Cambiarse o quitarse ropa"
          ]
        }
      }
    },
    {
      "id": "building_topology",
      "type": "multiple_choice",
      "title": "Building Topology",
      "answer_type": "string",
      "include_other": true,
      "infoText": "Which type of building best reflects the participants house?",
      "options": [
        "Apartment",
        "Rowhouse",
        "Standalone house"
      ],
      "include_na": false,
      "translation": {
        "gu": {
          "title": "મકાનનો પ્રકાર",
          "infoText": "બહેનો કેવા પ્રકાર ના મકાનમાં રહે છે ?",
          "options": [
            "અપાર્ટમેન્ટ",
            "રોહાઉસ",
            "સ્ટેન્ડઅલોન હાઉસ"
          ]
        },
        "hi": {
          "title": "बिल्डिंग टाइपोलॉजी",
          "infoText": "किस प्रकार की इमारत बहनों के घर को सबसे अच्छी तरह दर्शाती है?",
          "options": [
            "अपार्टमेन्ट",
            "रोहाउस",
            "स्टैन्डलोन हाउस"
          ]
        },
        "es": {
          "title": "Tipología del edificio",
          "infoText": "¿Qué tipo de edificio representa mejor la casa de la participante?",
          "options": [
            "Apartamento",
            "Casa adosada",
            "Casa independiente"
          ]
        }
      }
    },
    {
      "id": "house_configuration",
      "type": "multiple_choice",
      "title": "Housing configuration",
      "answer_type": "string",
      "include_other": true,
      "infoText": "Which type of housing configuration best reflects the participants house?",
      "options": [
        "1 R - one room only",
        "1 RK - typical configuration with inner room, outer room, and bathroom",
        "1 BHK - one room with hall and kitchen"
      ],
      "include_na": false,
      "translation": {
        "gu": {
          "title": "ઘરની રચના  ",
          "infoText": "બહેનો કેટલા રૂમના મકાનમાં રહે છે ?",
          "options": [
            "માત્ર એક રૂમ",
            "૧ RK - અંદરનો રૂમ, બહારનો રૂમ અને બાથરૂમ સાથેની સામાન્ય રચના",
            "૧ BHK-હોલ અને કિચન સાથેનો એક રૂમ"
          ]
        },
        "hi": {
          "title": "आवास व्यवस्था (घर कि बनावट)",
          "infoText": "किस प्रकार कि आवास व्यवस्था बहन के घर को सबसे अच्छी तरह दर्शाती है?",
          "options": [
            "1 R- मात्र एक रूम",
            "1 RK- आंतरिक कक्ष, बाहरी कक्ष और बाथरूम के साथ विशिष्ट समाकृति",
            "1 BHK- हॉल और रसोई के साथ एक कमरा"
          ]
        },
        "es": {
          "title": "Configuración de la vivienda",
          "infoText": "¿Qué tipo de configuración de vivienda representa mejor la casa de la participante?",
          "options": [
            "1 R - solo una habitación",
            "1 RK - configuración típica con una habitación interior, una exterior y baño",
            "1 BHK - una habitación con sala y cocina"
          ]
        }
      }
    },
    {
      "id": "floor_of_building",
      "type": "numeric",
      "min_value": -10,
      "max_value": 10,
      "title": "Floor of Building",
      "infoText": "Enter the floor number on which the participant lives. For ground floor, enter '0'.",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "મકાનના માળ",
          "infoText": "બહેનો ક્યાં માળ ઉપર રહે છે ? જો તેઓ સૌથી નીચે રહેતા હોય તો '0' લખો"
        },
        "hi": {
          "title": "इमारत कि मंजिलें",
          "infoText": "बहन जिस मंजीले पर रहती हैं उसका नंबर लिखें। जैसे ग्राउन्ड फ्लोर के लिए ‘0’ लिखें।"
        },
        "es": {
          "title": "Piso del edificio",
          "infoText": "Ingrese el número del piso en el que vive la participante. Para la planta baja, ingrese '0'."
        }
      }
    },
    {
      "id": "total_floors",
      "type": "numeric",
      "min_value": 1,
      "max_value": 10,
      "title": "Total number of floors ",
      "infoText": "Enter the total number of floors the building has. The ground floor should start at '1'.",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "કુલ માળોની સંખ્યા",
          "infoText": "કુલ કેટલા માળ છે તે લખો અને જો સૌથી નીચેનો માળ હોય તો '૧' થી શરૂઆત કરો."
        },
        "hi": {
          "title": "कुल मंजिलों कि संख्या",
          "infoText": "इमारत में मौजूद मंजिलों की कुल संख्या दर्ज करें। ग्राउन्ड फ्लोर को ‘1’ से शुरू करें।"
        },
        "es": {
          "title": "Número total de pisos",
          "infoText": "Ingrese el número total de pisos que tiene el edificio. La planta baja debe comenzar en '1'."
        }
      }
    },
    {
      "id": "roof_type",
      "type": "multiple_choice_images",
      "title": "Roof Type",
      "answer_type": "string",
      "include_other": true,
      "infoText": "Which type of roof best reflects the participants house?",
      "options": [
        "Flat roof",
        "Sloping roof",
        "Lean-to roof"
      ],
      "images": [
        "flat_roof.png",
        "sloping_roof.png",
        "lean_to_roof.png"
      ],
      "include_na": false,
      "translation": {
        "gu": {
          "title": "છતનો પ્રકાર",
          "infoText": "બહેનના ઘરના “છત” નો પ્રકાર કેવો છે તે નીચેના વિકલ્પ માંથી પસંદ કરો.",
          "options": [
            "સીધી છત",
            "ઢળેલી છત",
            "એકતરફી ઢળેલી  છત"
          ]
        },
        "hi": {
          "title": "छत के प्रकार",
          "infoText": "किस प्रकार की छत बहन के घर को सबसे अच्छी तरह दर्शाती है?",
          "options": [
            "समतल छत",
            "झुकी हुई छत",
            "ढलानदार छत"
          ]
        },
        "es": {
          "title": "Tipo de techo",
          "infoText": "¿Qué tipo de techo representa mejor la casa de la participante?",
          "options": [
            "Techo plano",
            "Techo inclinado",
            "Techo a dos aguas"
          ]
        }
      }
    },
    {
      "id": "house_age",
      "type": "multiple_choice",
      "title": "House Age",
      "answer_type": "string",
      "infoText": "What is the age (in years) of the participants house?",
      "options": [
        "5 years or less",
        "6 to 10 years",
        "11 to 15 years",
        "16 to 20 years",
        "21 years or more"
      ],
      "include_na": false,
      "translation": {
        "gu": {
          "title": "મકાનની ઉંમર",
          "infoText": "બહેનના મકાનની ઉંમર કેટલી છે તે લખો ( મકાન કેટલા વર્ષ જુનું છે તે )",
          "options": [
            "૫ વર્ષ કે તેથી ઓછા",
            "૬ થી ૧૦ વર્ષ",
            "૧૧ થી ૧૫ વર્ષ",
            "૧૬ થી ૨૦ વર્ષ",
            "૨૧ વર્ષ કે તેથી વધુ"
          ]
        },
        "hi": {
          "title": "घर कि उम्र",
          "infoText": "बहन के घर कि उम्र (साल मैं) कितनी है?",
          "options": [
            "5 साल या उससे कम",
            "6 से 10 साल",
            "11 से 15 साल",
            "16 से 20 साल",
            "21 साल या उससे ज्यादा"
          ]
        },
        "es": {
          "title": "Antigüedad de la casa",
          "infoText": "¿Cuántos años (en años) tiene la casa de la participante?",
          "options": [
            "5 años o menos",
            "6 a 10 años",
            "11 a 15 años",
            "16 a 20 años",
            "21 años o más"
          ]
        }
      }
    },
    {
      "id": "walling_material",
      "type": "multiple_choice_images",
      "title": "Walling material",
      "answer_type": "string",
      "include_other": true,
      "infoText": "What material are the homes' walls primarily made out of?",
      "options": [
        "Bricks",
        "Cement",
        "Tin sheet"
      ],
      "images": [
        "brick_wall.png",
        "cement_wall.png",
        "tin_wall.png"
      ],
      "include_na": false,
      "translation": {
        "gu": {
          "title": "દીવાલ બનાવવા માટેની કઈ સામગ્રી",
          "infoText": "મકાનની દિવાલો મુખ્યત્વે કઈ સામગ્રીમાંથી બનેલી છે ?",
          "options": [
            "ઇંટો",
            "સિમેન્ટ",
            "પતરાનું બનેલું "
          ]
        },
        "hi": {
          "title": "दीवार बनाने के लिए सामग्री",
          "infoText": "घरों की दीवारें मुख्यतः किस सामग्री से बनी हुई हैं?",
          "options": [
            "ईंट",
            "सिमेन्ट",
            "टिन शीट"
          ]
        },
        "es": {
          "title": "Material de las paredes",
          "infoText": "¿De qué material están hechas principalmente las paredes de la casa?",
          "options": [
            "Ladrillos",
            "Cemento",
            "Lámina de hojalata"
          ]
        }
      }
    },
    {
      "id": "internal_plaster",
      "type": "binary",
      "title": "Internal Plaster",
      "infoText": "Are the INTERNAL walls covered with plaster?",
      "yes": "Yes",
      "no": "No",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "અંદરનું  પ્લાસ્ટર",
          "infoText": "શું અંદરની દીવાલમાં પ્લાસ્ટર છે ?",
          "yes": "હા",
          "no": "ના"
        },
        "hi": {
          "title": "अंदरूनी पलास्टर",
          "infoText": "क्या अंदरूनी दीवारें प्लास्टर से ढकी हुई हैं?",
          "yes": "हाँ",
          "no": "नहीं"
        },
        "es": {
          "title": "Enlucido interno",
          "infoText": "¿Las paredes INTERNAS están cubiertas con yeso?",
          "yes": "Sí",
          "no": "No"
        }
      }
    },
    {
      "id": "external_plaster",
      "type": "binary",
      "title": "External Plaster",
      "infoText": "Are the External walls covered with plaster?",
      "yes": "Yes",
      "no": "No",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "બાહ્ય પ્લાસ્ટર",
          "infoText": " શું બહાર ની દીવાલમાં પ્લાસ્ટર છે ?",
          "yes": "હા",
          "no": "ના"
        },
        "hi": {
          "title": "बाहरी पलास्टर",
          "infoText": "क्या बाहरी दीवारें प्लास्टर से ढकी हुई हैं?",
          "yes": "हाँ",
          "no": "नहीं"
        },
        "es": {
          "title": "Enlucido externo",
          "infoText": "¿Las paredes EXTERNAS están cubiertas con yeso?",
          "yes": "Sí",
          "no": "No"
        }
      }
    },
    {
      "id": "roofing_material",
      "type": "multiple_choice_images",
      "title": "Roofing materials",
      "answer_type": "string",
      "include_other": true,
      "infoText": "What is the ROOF of the house primarily made out of?",
      "options": [
        "Tin sheet",
        "Cement sheet",
        "Ceramic tiles (Naliya)",
        "Reinforced Cement Concrete (RCC)"
      ],
      "images": [
        "tin_roof.png",
        "cement_roof.png",
        "tile_roof.png",
        "concrete_roof.png"
      ],
      "include_na": false,
      "translation": {
        "gu": {
          "title": "છત બનવાની ની સામગ્રી",
          "infoText": "મકાનના છાપરા શાના બનેલા છે ?",
          "options": [
            "પતરાનું બનેલું ",
            "સિમેન્ટ  શીટ",
            " સેરામિક ટાઇલ્સ (નળીયા)",
            "મજબૂત સિમેન્ટ કોનક્રીટ (RCC)"
          ]
        },
        "hi": {
          "title": "छत बनाने के लिए सामग्री",
          "infoText": "घरों की छत मुख्यतः किस सामग्री से बनी हुई हैं?",
          "options": [
            "टिन शीट",
            "सिमेन्ट शीट",
            "सरैमिक टाइल्स",
            "RCC"
          ]
        },
        "es": {
          "title": "Materiales del techo",
          "infoText": "¿De qué material está hecho principalmente el TECHO de la casa?",
          "options": [
            "Lámina de hojalata",
            "Lámina de cemento",
            "Tejas cerámicas (Naliya)",
            "Hormigón armado (RCC)"
          ]
        }
      }
    },
    {
      "id": "tarpaulin_sheet",
      "type": "multiple_choice_answer",
      "title": "Tarpaulin sheet",
      "include_other": true,
      "infoText": "Does the participant cover the roof with a tarpaulin sheet at any point during the year? Select all that apply?",
      "options": [
        "Yes - in Winter",
        "Yes - in Summer",
        "Yes - in Monsoon"
      ],
      "include_na": false,
      "none_text": "No - Never",
      "translation": {
        "gu": {
          "title": "ટારપોલિન શીટ",
          "infoText": "બહેન મકાનના છાપરાને વર્ષ દરમિયાન તારપોલિન શીટથી ઢાંકે છે? નીચેમાંથી  યોગ્ય વિકલ્પો પસંદ કરો.",
          "options": [
            "હા - શિયાળામાં",
            "હા - ગરીમીમાં",
            "હા - ચોમાસામાં "
          ],
          "none_text": "ના - ક્યારેય નહીં"
        },
        "hi": {
          "title": "टारपौलीन शीट (तिरपाल)",
          "infoText": "क्या बहन साल के किसी भी समय छत को तिरपाल से ढकती है? जो भी लागू हो उससे चुनें।",
          "options": [
            "हाँ- सर्दियों में",
            "हाँ- गर्मियों में",
            "हाँ- मानसून (बारिश/ऋतु) में"
          ],
          "none_text": "नहीं - कभी नहीं"
        },
        "es": {
          "title": "Lona",
          "infoText": "¿La participante cubre el techo con una lona en algún momento del año? Seleccione todas las que correspondan.",
          "options": [
            "Sí - en invierno",
            "Sí - en verano",
            "Sí - en el monzón"
          ],
          "none_text": "No - Nunca"
        }
      }
    },
    {
      "id": "roof_skylight",
      "type": "binary",
      "title": "Roof skylight or cut out ",
      "infoText": "Does the roof of the house have a skylight or cut out?",
      "yes": "Yes",
      "no": "No",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "છાપરાના સ્કાયલાઇટ અથવા કટઆઉટ",
          "infoText": "શું મકાનની છાપરામાં સ્કાયલાઇટ (પ્રકાશ અંદર આવે તેવું) અથવા કટઆઉટ (તિરાડ) છે?",
          "yes": "હા",
          "no": "ના"
        },
        "hi": {
          "title": "छत का रोशनदान या कट आउट (वेनटीलेशन के लिए छत के किसी भी भाग को हटा देना या काट देना)",
          "infoText": "क्या घर कि छत पर रोशनदान या कट आउट है?",
          "yes": "हाँ",
          "no": "नहीं"
        },
        "es": {
          "title": "Tragaluz o abertura en el techo",
          "infoText": "¿El techo de la casa tiene un tragaluz o abertura?",
          "yes": "Sí",
          "no": "No"
        }
      }
    },
    {
      "id": "roof_treatment",
      "type": "binary",
      "title": "Roof Treatment",
      "infoText": "Has the roof of the house been treated with a 'cool roof' coating?",
      "yes": "Yes",
      "no": "No",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "છાપરાની અન્ય કોઈ વ્યવસ્થા ",
          "infoText": "શું ઘરની છતને 'ઠંડી છત' ની પદ્ધતિ આપવામાં આવી છે?",
          "yes": "હા",
          "no": "ના"
        },
        "hi": {
          "title": "छत का प्रबंध",
          "infoText": "क्या घर की छत पर \"कूल रूफ\" कोटिंग की गई है?",
          "yes": "हाँ",
          "no": "नहीं"
        },
        "es": {
          "title": "Tratamiento del techo",
          "infoText": "¿El techo de la casa ha sido tratado con un recubrimiento de \"techo fresco\"?",
          "yes": "Sí",
          "no": "No"
        }
      }
    },
    {
      "id": "flooring_material",
      "type": "multiple_choice_images",
      "title": "Flooring Materials",
      "answer_type": "string",
      "include_other": true,
      "infoText": "What is the FLOOR of the house primarily made out of? ",
      "options": [
        "Stone",
        "Cement",
        "Tile"
      ],
      "images": [
        "stone_floor.png",
        "cement_floor.png",
        "tile_floor.png"
      ],
      "include_na": false,
      "translation": {
        "gu": {
          "title": "ફલોરિંગ મટીરીયલ ",
          "infoText": "મકાનની ફ્લોર(ભોય) મુખ્યત્વે કયા સામગ્રીમાંથી બનેલી છે?",
          "options": [
            "પથ્થર",
            "સિમેન્ટ",
            "ટાઇલ"
          ]
        },
        "hi": {
          "title": "फर्श बनाने के लिए सामग्री",
          "infoText": "घर का फर्श मुख्यतः किससे बना हुआ है?",
          "options": [
            "पत्थर",
            "सिमेन्ट",
            "टाइल्स"
          ]
        },
        "es": {
          "title": "Materiales del piso",
          "infoText": "¿De qué material está hecho principalmente el PISO de la casa?",
          "options": [
            "Piedra",
            "Cemento",
            "Baldosa"
          ]
        }
      }
    },
    {
      "id": "total_windows",
      "type": "numeric",
      "min_value": 0,
      "max_value": 10,
      "title": "Total Windows",
      "infoText": "How many windows does the house have in total?",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "કુલ બારીઓ ",
          "infoText": "મકાનમાં કુલ કેટલી બારી છે? "
        },
        "hi": {
          "title": "कुल खिड़कियां ",
          "infoText": "घर में कुल कितनी खिड़कियां हैं?"
        },
        "es": {
          "title": "Número total de ventanas",
          "infoText": "¿Cuántas ventanas tiene la casa en total?"
        }
      }
    },
    {
      "id": "total_vents",
      "type": "numeric",
      "min_value": 0,
      "max_value": 10,
      "title": "Total Vents",
      "infoText": "How many vents does the house have in total?",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "હવાની અવર જવર માટેના કુલ સાધનો  ",
          "infoText": "મકાનમાં કુલ કેટલા હવાઉજાસ છે?"
        },
        "hi": {
          "title": "कुल रोशनदान ",
          "infoText": "घर में कुल कितने रोशनदान (वायुमार्ग) हैं?"
        },
        "es": {
          "title": "Número total de ventilaciones",
          "infoText": "¿Cuántas ventilaciones tiene la casa en total?"
        }
      }
    },
    {
      "id": "total_doors",
      "type": "numeric",
      "min_value": 0,
      "max_value": 10,
      "title": "Total Doors",
      "infoText": "How many doors does the house have in total? ",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "કુલ દરવાજા",
          "infoText": "મકાનમાં કુલ કેટલા દરવાજા છે?"
        },
        "hi": {
          "title": "कुल दरवाज़े ",
          "infoText": "घर में कुल कितने दरवाज़े हैं?"
        },
        "es": {
          "title": "Número total de puertas",
          "infoText": "¿Cuántas puertas tiene la casa en total?"
        }
      }
    },
    {
      "id": "house_layout",
      "type": "image_upload",
      "title": "House Layout",
      "image": "layout_example.png",
      "infoText": "Upload a picture of the completed housing configuration sheet.\n\nTo complete the sheet: stand at the entrance to the home and on a printed house configuration sheet, please mark the position of the doors, windows, vents, stove, and the sensors – Kestrel, Govee, and Hobo. For the hobo sensor please also mark the position of the black globe thermometer. Please also mark North. Here is an example:",
      "include_na": true,
      "translation": {
        "gu": {
          "title": "મકાનની લેઆઉટ",
          "infoText": "આખા મકાનની સંરચનાની શીટની અપલોડ કરો. ઘરના પ્રવેશદ્વાર પર અને હાઉસ કન્ફિગરેશન શીટ પર ઊભા રહો, ઘરના દરવાજા, બારીઓ,  સ્ટોવ,સેન્સર (Kestrel, Govee અને Hobo) ની સ્થિતિને ચિન્હ કરો. હોબો સેન્સર માટે બ્લેક ગ્લોબ થર્મોમીટરની સ્થિતિને પણ ચિન્હ કરો.  ઉત્તર દિશાને પણ ચિન્હ કરો."
        },
        "hi": {
          "title": "घर का लेआउट",
          "infoText": "पूरी आवास व्यवस्था या घर के बनावट कि शीट कि एक तस्वीर अपलोड करें। शीट को पूरा करने के लिए: घर के प्रवेश द्वार पर खड़े हों और मुद्रित हाउस कन्फिग्यरैशन शीट पर, कृपया दारवाज़े, खिड़कियां, वेंट, स्टोव और सेन्सर- केस्ट्रल, गोवी और होबो के स्थानों को चिन्हित करें। होबो सेन्सर के लिए कृपया ब्लैक ग्लोब थर्मामीटर के स्थान को भी चिन्हित करें। कृपया उत्तर दिशा को भी चिन्हित करें। "
        },
        "es": {
          "title": "Distribución de la casa",
          "infoText": "Suba una foto de la hoja completada de configuración de vivienda.\n\nPara completar la hoja: párese en la entrada de la casa y, en una hoja impresa de configuración de vivienda, marque la posición de las puertas, ventanas, ventilaciones, estufa y los sensores – Kestrel, Govee y Hobo. Para el sensor Hobo, marque también la posición del termómetro de globo negro. Marque también el norte. Aquí tiene un ejemplo:"
        }
      }
    },
    {
      "id": "total_young_males",
      "type": "numeric",
      "min_value": 0,
      "max_value": 15,
      "title": "Occupant details - Young males",
      "infoText": "Enter the number of males aged 17 years or younger normally living in the house.",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "મકાનમાં રહેતા છોકરાઓની સંખ્યા",
          "infoText": "મકાનમાં સામાન્ય રીતે રહેતા ૧૭ વર્ષ અથવા તેની નીચેના પુરુષોની સંખ્યા લખો."
        },
        "hi": {
          "title": "निवासी विवरण- युवा पुरुष",
          "infoText": "17 साल या उससे छोटे पुरुषों कि संख्या दर्ज करें जो सामान्यत उसी घर में रहते हैं।"
        },
        "es": {
          "title": "Detalles de ocupantes - Jóvenes varones",
          "infoText": "Ingrese el número de varones de 17 años o menos que viven normalmente en la casa."
        }
      }
    },
    {
      "id": "total_adult_males",
      "type": "numeric",
      "min_value": 0,
      "max_value": 15,
      "title": "Occupant details - Young males",
      "infoText": "Enter the number of males aged 18 years or older normally living in the house.",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "મકાનમાં રહેતા પુરુષોની સંખ્યા",
          "infoText": "મકાનમાં સામાન્ય રીતે રહેતા ૧૮વર્ષ અથવા તેનાથી વધુ ઉંમર ધરાવતા પુરુષોની સંખ્યા લખો."
        },
        "hi": {
          "title": "निवासी विवरण- वयस्क पुरुष",
          "infoText": "18 साल या उससे ऊपर के पुरुषों कि संख्या दर्ज करें जो सामान्यत उसी घर में रहते हैं।"
        },
        "es": {
          "title": "Detalles de ocupantes - Varones adultos",
          "infoText": "Ingrese el número de varones de 18 años o más que viven normalmente en la casa."
        }
      }
    },
    {
      "id": "total_young_females",
      "type": "numeric",
      "min_value": 0,
      "max_value": 15,
      "title": "Occupant details - Young females",
      "infoText": "Enter the number of females aged 17 years or younger normally living in the house.",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "મકાનમાં રહેતી છોકરીઓની સંખ્યા",
          "infoText": "મકાનમાં સામાન્ય રીતે રહેતી ૧૭ વર્ષ અથવા તેનાથી ઓછા ઉંમર ધરાવતી મહિલાઓની સંખ્યા લખો."
        },
        "hi": {
          "title": "निवासी विवरण- युवा महिलायें",
          "infoText": "17 साल या उससे छोटी महिलाओं कि संख्या दर्ज करें जो सामान्यत उसी घर में रहती हैं।"
        },
        "es": {
          "title": "Detalles de ocupantes - Mujeres Jóvenes",
          "infoText": "Ingrese el número de mujeres de 17 años o menos que viven normalmente en la casa."
        }
      }
    },
    {
      "id": "total_adult_females",
      "type": "numeric",
      "min_value": 0,
      "max_value": 15,
      "title": "Occupant details - Young females",
      "infoText": "Enter the number of females aged 18 years or older normally living in the house.",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "મકાનમાં રહેતી સ્ત્રીઓની સંખ્યા",
          "infoText": "મકાનમાં સામાન્ય રીતે રહેતી ૧૮ વર્ષ અથવા તેનાથી વધુ ઉંમર ધરાવતી મહિલાઓની સંખ્યા લખો."
        },
        "hi": {
          "title": "निवासी विवरण- वयस्क महिलायें",
          "infoText": "18 साल या उससे ऊपर कि महिलाओं कि संख्या दर्ज करें जो सामान्यत उसी घर में रहती हैं।"
        },
        "es": {
          "title": "Detalles de ocupantes - Mujeres adultas",
          "infoText": "Ingrese el número de mujeres de 18 años o más que viven normalmente en la casa."
        }
      }
    },
    {
      "id": "household_appliances",
      "type": "multiple_choice_answer",
      "title": "Household appliances",
      "infoText": "Which of the following appliances does the participant have in their house? Select all that apply.",
      "options": [
        "Air conditioner(s)",
        "Ceiling fan(s)",
        "Table fan(s)",
        "Air cooler(s)",
        "Refrigerator(s)"
      ],
      "include_na": false,
      "translation": {
        "gu": {
          "title": "ઘરની ઉપકરણો",
          "infoText": "બહેનના ઘરમાં નીચેની મુજબના કોઈ પણ ઉપકરણો  છે તે પસંદ કરો. નીચેના માંથી યોગ્ય વિકલ્પો પસંદ કરો.",
          "options": [
            "એર કન્ડિશનર",
            "સીલીંગ પંખા",
            "ટેબલ ફેન",
            "એર કૂલર",
            "ફ્રિજ"
          ]
        },
        "hi": {
          "title": "घरेलू उपकरण",
          "infoText": "बहन के घर में निम्नलिखित में से कौन से उपकरण हैं? जो भी लागू हो उसे चुनें।",
          "options": [
            "ऐसी",
            "सीलिंग फैन",
            "टेबल फैन",
            "एयर कूलर",
            "फ्रिज"
          ]
        },
        "es": {
          "title": "Electrodomésticos",
          "infoText": "¿Cuáles de los siguientes electrodomésticos tiene la participante en su casa? Seleccione todas las que correspondan.",
          "options": [
            "Aire acondicionado(s)",
            "Ventilador(es) de techo",
            "Ventilador(es) de mesa",
            "Enfriador(es) de aire",
            "Refrigerador(es)"
          ]
        }
      }
    },
    {
      "id": "electricity_bill",
      "type": "image_upload",
      "title": "Electricity bill",
      "infoText": "Upload a picture of the participants most recent electricity bill.",
      "include_na": true,
      "translation": {
        "gu": {
          "title": "વીજળી નું બિલ",
          "infoText": "બહેનના ઘરનું હાલું વીજળી બીલ જોડો"
        },
        "hi": {
          "title": "बिजली बिल",
          "infoText": "बहन के सबसे हाल ही के बिजली बिल कि फोटो अपलोड करें।"
        },
        "es": {
          "title": "Factura de electricidad",
          "infoText": "Suba una foto de la factura de electricidad más reciente de la participante."
        }
      }
    },
    {
      "id": "front_of_house",
      "type": "image_upload",
      "title": "Front of the house",
      "infoText": "Upload a picture of the front of the participants house. Try to get as much of the house in the picture as possible.",
      "include_na": true,
      "translation": {
        "gu": {
          "title": "ઘરના આગળનો ભાગ",
          "infoText": "બહેનોના ઘરના આગળના ભાગનો ફોટો જોડો. શક્ય તેટલું ફોટામાં ઘણા પુરા ભાગને સમાવાનો પ્રયત્ન કરો"
        },
        "hi": {
          "title": "घर के आगे (सामने) का भाग",
          "infoText": "बहन के घर के आगे (सामने) के भाग कि फोटो अपलोड करें। जितना संभव हो फोटो में घर का अधिक से अधिक भाग लेने का प्रयास करें।"
        },
        "es": {
          "title": "Fachada de la casa",
          "infoText": "Suba una foto de la fachada de la casa de la participante. Intente incluir la mayor parte de la casa en la foto."
        }
      }
    },
    {
      "id": "notes",
      "type": "text_area",
      "title": "Notes",
      "infoText": "Please write down any additional notes",
      "include_na": true,
      "translation": {
        "gu": {
          "title": "વધારાની માહિતી",
          "infoText": "ઉપરના સવાલ ઉપરાંત કોઈ પણ વધારાની નોંધ હોય તો લખો."
        },
        "hi": {
          "title": "नोट्स (टिप्पणी)",
          "infoText": "कृपया कोई अतिरिक्त नोट लिखें"
        },
        "es": {
          "title": "Notas",
          "infoText": "Escriba cualquier nota adicional."
        }
      }
    }
  ]
} 
export default CONFIG