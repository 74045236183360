// Extra Requests Components
import React, { useEffect, useState } from 'react';
import { getATOInsideSetField, RefATOField, RefATOInsideSetField, RefStoredValue, setATOFieldInsideSet, setStoredValue, StateStoredValue } from '../hooks/StoreHooks';
import { RefLanguage } from '../hooks/LanguageHooks';
import CustomText from '../elements/CustomText';
import * as locCon from '../LocalConstants';
import * as libCon from '../community-hats-js-library/Constants';
import { checkSewaId, isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { SimpleCopyField, SimpleCopyFieldFromATOInsideSet, SimpleStoreCopyFieldFromATO } from '../elements/SimpleStoreCopyField';
import { SimpleStoreCopySelectionFieldFromATOInsideSet } from '../elements/SimpleStoreCopySelectionField';
import CustomButton from '../elements/CustomButton';
import { SyncATOButton, SyncATOInsideSetButton } from '../elements/SyncATOButton';
import { getPendingExtraRequests } from '../utils/extrasFunctions';
import CustomSpin from '../elements/CustomSpin';
import { formatDistanceShort, formatToIndiaTimeReadable, nowISO } from '../community-hats-js-library/utils/dateFunctions';
import CollectionStatus from '../elements/CollectionStatus';
import { RefExtraRequestStatus } from '../hooks/BundleDeviceHooks';
import CheckboxGroup from '../elements/CheckboxGroup';


export const ExtraRequestViewer = () => {

    const i18n = RefLanguage()

    // Pending Items and Last Issue Checked
    const [pendingItems, setPendingItems] = StateStoredValue(locCon.EXTRA_REQUEST_PENDING_ITEMS)
    const [lastExtraRequestCheck, setLastExtraRequestCheck] = StateStoredValue(locCon.LAST_EXTRA_REQUEST_CHECK)

    // Loading
    const [isLoading, setIsLoading] = useState(false)

    // Status and Message
    const [status, message] = RefExtraRequestStatus()


    const refreshPendingItems = async () => {

        setIsLoading(true)

        let newPenndingIems = await getPendingExtraRequests()
        setPendingItems(newPenndingIems)
        setLastExtraRequestCheck(nowISO())

        setIsLoading(false)

    }


    return (
        <div className='verticalSection'>
            <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("extraRequests")}</CustomText>

            <CollectionStatus status={status} message={message} />


            <SimpleCopyField title={i18n.t("lastRefreshed")} value={isNullOrUndefined(lastExtraRequestCheck) ? i18n.t("never") : <>{`${formatToIndiaTimeReadable(lastExtraRequestCheck, true, false)}`}<br />{`(${formatDistanceShort(lastExtraRequestCheck)})`}</>} enableCopy={false} />
            {
                isLoading
                    ? <CustomSpin type={locCon.BUTTON_SPINNER} />
                    : <CustomButton type={locCon.PRIMARY_BUTTON} onClick={refreshPendingItems}>{i18n.t("refresh")}</CustomButton>
            }


            {
                pendingItems.map((item, i) => <div style={{ backgroundColor: "var(--background-color-2)", marginTop: "1vh", marginBottom: "1vh", width: "95%" }} key={i}>
                    <ExtraRequestItem item={item} />
                </div>)
            }

        </div>
    )




}

export const ExtraRequestItem = ({ item }) => {

    switch (item) {
        case locCon.EXTRA_REQUEST_UPDATE_SENSOR_POSITION:
            return (<UpdateSensorPlacementPositionsRequest />)
        case locCon.EXTRA_REQUEST_UPDATE_MISSING_SEWA_ID:
            return <ParticipantIdUpdater />
        default:
            console.error(`Extra Request Item ${item} not recognized`)
            return (<div></div>);
    }

}


export const UpdateSensorPlacementPositionsRequest = () => {

    const i18n = RefLanguage()

    const pendingPositionIds = RefStoredValue(locCon.EXTRA_REQUEST_UPDATE_SENSOR_POSITION_MISSING_IDS)

    return (
        <div className='verticalSection'>
            <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("pendingSensorPlacementPositions")}</CustomText>
            {
                pendingPositionIds.length > 0
                    ? <div className='verticalSection' >
                        <CustomText type={locCon.INFO_TEXT}>{i18n.t("pendingSensorPlacementPositionsInstructions")}</CustomText>
                        {pendingPositionIds.map(posId => <div key={posId} style={{ border: "2px solid var(--primary-color-2)", borderRadius: "4%", backgroundColor: "var(--background-color-2)", marginTop: "1vh", marginBottom: "1vh", width: "95%" }}>
                            <CheckboxGroup disableClick={true} mainCheckbox={{
                                [libCon.ID]: locCon.getUpdateSensorPositionCheckBoxId(posId),
                                [libCon.TEXT]: i18n.t("updateSensorPosition")
                            }}
                            />
                            <UpdateSingleSensorPlacementPositions positionId={posId} />
                        </div>)}
                    </div>
                    : <div className='verticalSection'>
                        <CustomText type={locCon.SUCCESS_TEXT}>{i18n.t("allSensorPositionsUpToDate")}</CustomText>
                        <CustomText type={locCon.SUCCESS_TEXT}>{i18n.t("pleaseRefresh")}</CustomText>
                    </div>
            }


        </div>
    )



}

export const UpdateSingleSensorPlacementPositions = ({ positionId }) => {

    const i18n = RefLanguage()

    const newPosition = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_POSITION)


    // If checked is false, set position to null and updates old position with crrent
    useEffect(() => {

        let currentPosition = getATOInsideSetField(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_POSITION)
        let currentPositionChecked = getATOInsideSetField(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_POSITION_CHECKED)

        if (!isNullOrUndefined(currentPosition) && currentPositionChecked !== libCon.YES) {
            setATOFieldInsideSet(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_POSITION, null)
            setATOFieldInsideSet(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_OLD_POSITION, currentPosition)
        }

    }, [positionId])


    return (
        <div className='verticalSection'>

            <div style={{ marginTop: 15 }}>
                <SimpleCopyFieldFromATOInsideSet title={i18n.t("serial")} atoId={locCon.AT_OBJECT_SENSOR} positionId={positionId} fieldId={libCon.ATF_SERIAL} enableCopy={false} />
            </div>
            <div className='horizontalSection'>
                <div style={{ width: "50%" }}>
                    <SimpleCopyFieldFromATOInsideSet title={i18n.t("brand")} atoId={locCon.AT_OBJECT_SENSOR} positionId={positionId} fieldId={libCon.ATF_BRAND} enableCopy={false} />
                </div>
                <div style={{ width: "50%" }}>
                    <SimpleCopyFieldFromATOInsideSet title={i18n.t("model")} atoId={locCon.AT_OBJECT_SENSOR} positionId={positionId} fieldId={libCon.ATF_MODEL} enableCopy={false} />
                </div>
            </div>
            <SimpleStoreCopySelectionFieldFromATOInsideSet onClickCleanup={(val) => !isNullOrUndefined(val) && setATOFieldInsideSet(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_POSITION_CHECKED, libCon.YES)} title={i18n.t("senorPosition")} positionId={positionId} atoId={locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT} fieldId={libCon.ATF_POSITION} options={libCon.SENSOR_POSITIONS} optionsTextDict={libCon.SENSOR_POSITIONS.reduce((dict, item) => { dict[item] = i18n.t(item); return dict; }, {})} />
            <SyncATOInsideSetButton positionId={positionId} atoId={locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT} atoBehavior={libCon.ATO_BEHAVIOR_UPDATE} forceDisable={isNullOrUndefined(newPosition)} forceDisabledText={i18n.t("pleaseUpdateSensorPosition")} becomeTextOnSuccess={!isNullOrUndefined(newPosition)} onClickCleanup={(val) => setStoredValue(locCon.getUpdateSensorPositionCheckBoxId(positionId), val)} />
        </div>
    )

}


export function ParticipantIdUpdater() {

    const i18n = RefLanguage()

    const sewaId = RefATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID)


    return (
        <div className='verticalSection'>
            <CheckboxGroup disableClick={true} mainCheckbox={{
                [libCon.ID]: locCon.EXTRA_REQUEST_UPDATE_MISSING_SEWA_ID_CHECK_BOX,
                [libCon.TEXT]: i18n.t("updateSewaId")
            }}
            />
            <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("updateSewaIdInfo")}</CustomText>
            {
                isNullOrUndefined(sewaId) || sewaId === libCon.MISSING
                    ? <div className='verticalSection'>
                        <SimpleStoreCopyFieldFromATO includeCheckbox={true} atoId={locCon.AT_OBJECT_PARTICIPANT} fieldId={libCon.ATF_SEWA_ID} title={i18n.t("sewaId")} infoText={i18n.t("sewaIdText")} placeHolder={i18n.t("sewaId")} checkFunction={checkSewaId} defaultValue={libCon.MISSING} checkboxText={i18n.t("sewaIdCheckboxText")} />
                        <SyncATOButton atoId={locCon.AT_OBJECT_PARTICIPANT} atoBehavior={libCon.ATO_BEHAVIOR_UPDATE} onClickCleanup={(val) => val} forceDisable={isNullOrUndefined(sewaId) || sewaId === libCon.MISSING} forceDisabledText={i18n.t("pleaseUpdateTheSewaID")} />
                    </div>
                    : <CustomText type={locCon.INFO_TEXT}>{i18n.t("successSewaIdUpdated")}</CustomText>
            }

        </div>
    )
}