import { ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import * as locCon from "../LocalConstants"
import CustomButton from './CustomButton';
import { RefLanguage } from '../hooks/LanguageHooks';

function BackButton() {

  const navigate = useNavigate();

  return (
    <CustomButton type={locCon.SAVE_EDIT_BUTTON} onClick={() => navigate(-1)}><ArrowLeftOutlined /></CustomButton>
  )
}

export function BackButtonLarge() {

  const navigate = useNavigate();
  const i18n = RefLanguage()


  return (
    <CustomButton type={locCon.PRIMARY_BUTTON} style={{ marginTop: 10 }} onClick={() => navigate(-1)}>
      {i18n.t("goBack")}
    </CustomButton>
  )
}

export default BackButton

