const CONFIG = {
  "aws_access_key": "f4385f4fa3287d623ffd0f7ff1664afc:d6fd0b1c2d3265d52c134eae276854e77976f43cad62dac3e509f0bf1f9e8fb9",
  "aws_secret_key": "a972a795483b83c9c77a1b16c06eab68:f28c114ae45addd9045d5fd9a740007e60a887372a113f6f85a4efb613a17dd473eaf3869cf543a2809b33cc02111be0",
  "map_key": "0b247213d59f437bdc612e8d61ce2ac1:042530a74f623d33954a5e1f139b658d2f50932862854a006d396ded2c69c26b",
  "airtable_key": "d91c3770f64c98343e3159af4421492d:8fbef2c1288468b35fff503a5fb80fdc6c02cd0c5b70fa72d58cc30cd750e054c40e84cfcc39f8de98d392854204a9116e8eecfe342071189186ae9f12ec50149364e8f52215e5c5e6eb2c127110b993fe8c3c972ae7cb9da96ad5fb3ba2d40d",
  "aws_region": "fa9ded7582a3ff756e3775e70de19676:45b7127274c228dc04b8064dbbb026b7",
  "aws_sensors_bucket": "389119a72f6783f22ade7f265b9413e7:94d5cce5065ea27771e7bb061a18123758f91af0fa013fcb59f3ba4b6c173e1a",
  "aws_public_bucket": "ccf69b5dab3a8c53862eadf01cd25a5c:8572f2ed66a3b47c11c39656b7912d9437384db6840bb7e0727095fe0347993c",
  "password_hash": "4e134edc99ced9624e78a1aac20b1564:a81d335512a5b4974e9940ccb4ad2373df75adbf14bceadcf94518c0a610497fe83dcb26405697d9df4835054e464f85c35e41435adce972fca2841a8eb2be4a31a61b5cc32ecb21725495f41a9905ef",
  "fitbit_app_id": "7370361bc067246ffdf8f581abf1ecfd:3c761236079ab4bee0ed228374cee0ea",
  "sensor_inbox_email": "c9d8a6bc90d4196b25b58d112b87e4c1:d352054e317e5b4c4345a4c18b7155e92e8e97599fd460ee8a3d2fdb7152525c",
  "at_app_id": "2bca53cce434a61b4b435cfc8cb7f203:f5d55934639279d89839e8019ced9cc2de1d6e6d8a88a934454fcc20650633d7",
  "at_sensor_placement_form": "063c9d90fe00458bddc33c27d6fb19d3:f87d01e61d46afbf14d94fc17b3e80e0106fe557257076a1cd5a18f0178252739458b7f0f6735644e73ace2541cf3a6c33e2df4ba51a461a976ba78c785c7251",
  "at_govee_activation_codes_id": "f739be477231ca07f5bd415c1de66ef5:9e9b116d44eaf1e00c8f0e404bd6e1f080a2ebc928e35d04b050e0981c5268a4",
  "at_emails": "f879c03d7b9bcc9c3e4a6ac91c282dab:6ddc13bfb44964fd0cc64aa26911c21eb191f46a9a5f5b2be017077b2caaf13f",
  "at_participants": "f0b057d0a29e1ed89fad381a550f050a:684437496dcc765e88b75093ea994cdb569dcae562db5769603395301c795b8c",
  "at_phones": "1515512b844ed644b9f4784c7fa88cd0:b640090ceb118a314e23f7ed5696dd2e97a03ba23f4097879567acf5d020b4f2",
  "at_phone_placements": "4b1be413b8d12446b37f8fda027f06bc:98e515789100400d018ef99597e26f8448cb4855457c8caeb235ae61b09ed370",
  "at_wearables": "cbf0bd10c7662cc50d954ffcd26d0911:d812a37e843e884aea5cf4d4b767930edb79ad93e7fadac61839edff3881232c",
  "at_wearable_placements": "04a25be00119c06146e129088ed9050d:f8e8a6cf243985f0c66ede6dab137e65f9caf0972b82b12c59341e5473f3ee4a",
  "at_houses": "614094aba0707e8a5d81d6e805853442:00e5e2de94235c1c79b0c2deeeb642e260e4b3ae09f53cd46f50166eccd016ca",
  "at_participant_placement": "879f303e42867b0bd4e2a8f2ec1cafb5:fdf79c3dd85696e0d2bea8b7e4a50e8c427f254b1f577bd1d291b8c1cd3c6e6c",
  "at_sensors": "49f4f52264a82cc5c15e0474e8c52439:21190e84388a99bd596e6069c70eeb2e7cfaef84a55c5841a0523dfb98cb6861",
  "at_sensor_house_placement": "3ed97ca2a414574a75eee8bc710d0a15:aff98264314fc6dd9ceeaf694937592c3752862b3f307b16feea609aaa99abcd",
  "at_received_sensor_files": "7ae1b137997dcf20a0e12f8d4cad323e:bad2e18fd813ce5fe91be50330fafb22f7cf5be71758acdbd1570b704d42cdc9",
  "at_bundles": "322167f75e17f7a0cf5207f5303a679b:5f98a1a1fd736e3f883b0084fcf37b9ff5c055cb0f995cb1a5e1dba27975fb14",
  "at_issues": "712cdf17fb4ed967aaf5d60471a8b3de:61b6845e7cdb80e7ecead1d79b8d74952c9485c749c3b146eb56c8a97951679e",
  "at_perceptual_surveys": "d8b6b40e8e67a4fa3d4e190bea0ee7b3:3afe22dbba60d6818f281bebf8141cdf34289c9aa613205e58682d46921a8d48",
  "at_migrations": "93de17485fcbf95b0f00278d0e7bcd61:eba34d1b2b9d2f9914bb668bd6ec368fcd795698f2ef02d3a854738489b1be5e",
  "at_collection_records": "ece6f674b0aa7f91487e9d5960062e61:fc6e2c3f78b4bb28eaabead1b78b1290e1162be2d94d8aa7b75c3528bb39143a",
  "at_participant_intake_forms": "7926c289e6993ea2b2ed669d39b96693:9923dda43a6ddbc96a3a517c2533f269e089a6ac26e9232316b9c75e0151efb2",
  "at_house_intake_forms": "25334a30c339794aa8dde6153e9572ba:e08b075e08aa2590f9ebab739f3d614ae69b9ebe1aa4b35a662cdfab91f9dbf4",
  "at_pending_questions_participant_if": "e25a9e52baa3b261433449d083bb0e9b:b2a066fda137eacb9c2b6c2a92f6c788134d23676bf7be9a49328fb95d8268d8",
  "at_pending_questions_house_if": "e1f2ae9d83e8983fcf15ab3001a79192:1740e364dc86d1a37e8ccd0513fcb6fd89c57ee194ed82ddf51ff9b648a0b8fa",
  "at_wearable_coverage": "4d4c449c84fe313683d58f2af52230ec:28ccde5f31f649ae5ed8626893587d7f84ab3ea31cb7bd4303604cc55bfbf63d",
  "at_mapped_lab_report_record": "c09708e06c75956e0cd27e54505d4071:ec436254dccd2def9c96a9398e3bb03403f9484ee8c720fcd9990872473c65ca",
  "at_received_lab_reports": "57839ca2729eed0441a9aa106a393fc9:22b8bbe3a4e9ce901484104727d7b8e96755eae443ff0f9b74b73c6914668357"
} 
export default CONFIG