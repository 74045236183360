const CONFIG = {
  "metadata": {
    "version": "2024.12.04"
  },
  "structure": [
    {
      "id": "body_temperature",
      "type": "multiple_choice",
      "answer_type": "numeric",
      "min_value": -3,
      "max_value": 3,
      "title": "Body Temperature",
      "infoText": "Right now, how does the temperature of your body feel?",
      "options": [
        "Cold",
        "Cool",
        "Slightly Cool",
        "Neutral",
        "Slightly Warm",
        "Warm",
        "Hot"
      ],
      "include_na": true,
      "translation": {
        "gu": {
          "title": "શરીરનું તાપમાન",
          "infoText": "અત્યારે શરીર નું તાપમાન તમને કેવું લાગે છે?",
          "options": [
            "વધારે ઠંડુ",
            "ઠંડુ",
            "થોડું કે સહેજ ઠંડુ",
            "સામાન્ય/નોર્મલ",
            "થોડું ગરમ",
            "ગરમ",
            "વધારે ગરમ"
          ]
        },
        "hi": {
          "title": "शरीर का तापमान",
          "infoText": "अभी आपके शरीर का तापमान कैसा महसूस होता है?",
          "options": [
            "बहुत ठंडा",
            "ठंडा",
            "थोड़ा ठंडा",
            "तटस्थ (ठीक-ठीक)",
            "थोड़ा गर्म",
            "गर्म",
            "बहुत गर्म"
          ]
        },
        "es": {
          "title": "Temperatura Corporal",
          "infoText": "En este momento, ¿cómo siente la temperatura de su cuerpo?",
          "options": [
            "Frío",
            "Fresco",
            "Ligeramente fresco",
            "Neutro",
            "Ligeramente cálido",
            "Cálido",
            "Caliente"
          ]
        }
      }
    },
    {
      "id": "current_thermal_environment",
      "type": "multiple_choice",
      "answer_type": "numeric",
      "min_value": -2,
      "max_value": 1,
      "title": "Current Thermal Environment",
      "infoText": "Right now, how acceptable is your thermal environment?",
      "options": [
        "Very unacceptable",
        "Unacceptable",
        "Acceptable",
        "Very acceptable"
      ],
      "include_na": true,
      "translation": {
        "es": {
          "title": "Ambiente Térmico Actual",
          "infoText": "En este momento, ¿qué tan aceptable encuentra su ambiente térmico?",
          "options": [
            "Muy inaceptable",
            "Inaceptable",
            "Aceptable",
            "Muy aceptable"
          ]
        },
        "gu": {
          "title": "હાલનું ઘરની અંદર નું  વાતાવરણ",
          "infoText": "હાલનું ઘરની અંદર નું તાપમાન બરોબર લાગે છે ?",
          "options": [
            "બિલકુલ બરોબર નથી",
            "બરોબર નથી",
            "બરોબર છે",
            "એકદમ બરોબર છે"
          ]
        },
        "hi": {
          "title": "अभी का तापीय वातावरण (अपने चारों ओर का तापमान)",
          "infoText": "अभी तापीय वातावरण कितना स्वीकार्य है?",
          "options": [
            "बिल्कुल भी स्वीकार्य नहीं",
            "स्वीकार्य नहीं",
            "स्वीकार्य है",
            "पूरी तरह स्वीकार्य है"
          ]
        }
      }
    },
    {
      "id": "want_thermal_environment",
      "type": "multiple_choice",
      "answer_type": "numeric",
      "title": "Change in Thermal Environment",
      "min_value": -2,
      "max_value": 2,
      "infoText": "What changes do you want in your thermal environment?",
      "options": [
        "Want warmer",
        "Want slightly warmer",
        "No change",
        "Want slightly cooler",
        "Want cooler"
      ],
      "include_na": true,
      "translation": {
        "es": {
          "title": "Cambio en el Ambiente Térmico",
          "infoText": "¿Qué cambios desea en su ambiente térmico?",
          "options": [
            "Quiero más calor",
            "Quiero un poco más de calor",
            "Sin cambios",
            "Quiero un poco más de fresco",
            "Quiero más fresco"
          ]
        },
        "gu": {
          "title": "ઘરની અંદરના વાતાવરણમાં બદલાવ",
          "infoText": "ઘરની અંદરના તાપમાનમાં શું બદલાવ જોઈએ છે?",
          "options": [
            "ગરમ જોઈએ છે",
            "સહેજ ગરમ જોઈએ છે",
            "કોઈ બદલાવ નથી ઈચ્છતા",
            "સહેજ ઠંડુ જોઈએ છે",
            "ઠંડુ જોઈએ છે"
          ]
        },
        "hi": {
          "title": "तापीय वातावरण में बदलाव",
          "infoText": "आप अपने तापीय वातावरण में क्या बदलाव चाहती हैं?",
          "options": [
            "गर्मी चाहती हूँ",
            "हल्की गर्मी चाहती हूँ",
            "कोई बदलाव नहीं चाहती",
            "हल्की ठंड चाहती हूँ",
            "ठंड चाहती हूँ"
          ]
        }
      }
    },
    {
      "id": "current_air_movement",
      "type": "multiple_choice",
      "answer_type": "numeric",
      "title": "Current Air Movement",
      "min_value": -2,
      "max_value": 1,
      "infoText": "How acceptable is the air movement right now?",
      "options": [
        "Very unacceptable",
        "Unacceptable",
        "Acceptable",
        "Very acceptable"
      ],
      "include_na": true,
      "translation": {
        "es": {
          "title": "Movimiento de Aire Actual",
          "infoText": "¿Qué tan aceptable es el movimiento de aire en este momento?",
          "options": [
            "Muy inaceptable",
            "Inaceptable",
            "Aceptable",
            "Muy aceptable"
          ]
        },
        "gu": {
          "title": "હાલમાં ઘરમાં હવાની હેરફેર",
          "infoText": "હાલમાં ઘરમાં હવાની હેરફેર બરાબર લાગે છે ?",
          "options": [
            "બિલકુલ બરોબર નથી",
            "બરોબર નથી",
            "બરોબર છે",
            "એકદમ બરોબર છે"
          ]
        },
        "hi": {
          "title": "वर्तमान वायु (हवा) संचालन (प्रवाह, गति)",
          "infoText": "अभी वायु संचालन कितना स्वीकार्य है?",
          "options": [
            "बिल्कुल भी स्वीकार्य नहीं",
            "स्वीकार्य नहीं",
            "स्वीकार्य है",
            "पूरी तरह स्वीकार्य है"
          ]
        }
      }
    },
    {
      "id": "want_air_movement",
      "type": "multiple_choice",
      "answer_type": "numeric",
      "title": "Change in Air Movement",
      "min_value": -1,
      "max_value": 1,
      "infoText": "What change would you like in the air movement right now?",
      "options": [
        "Want more air movement",
        "No change",
        "Want less air movement"
      ],
      "include_na": true,
      "translation": {
        "es": {
          "title": "Cambio en el Movimiento de Aire",
          "infoText": "¿Qué cambio le gustaría en el movimiento de aire en este momento?",
          "options": [
            "Quiero más movimiento de aire",
            "Sin cambios",
            "Quiero menos movimiento de aire"
          ]
        },
        "gu": {
          "title": "ઘરની હવાની હેરફેરમાં બદલાવ",
          "infoText": "ઘરની હવાની હેરફેરમાં શું બદલાવ લાવવા ઈચ્છો છો?",
          "options": [
            "વધુ હવાનો હેરફેર ઈચ્છો છો",
            "કોઈ બદલાવ નથી ઈચ્છતા",
            "ઓછી હવાની ગતિ ઈચ્છો છો"
          ]
        },
        "hi": {
          "title": "वायु संचालन में बदलाव",
          "infoText": "अभी के वायु संचालन में आप क्या बदलाव चाहती हैं?",
          "options": [
            "ज्यादा वायु संचालन चाहती हूँ",
            "कोई बदलाव नहीं चाहती",
            "कम वायु संचालन चाहती हूँ"
          ]
        }
      }
    },
    {
      "id": "overall_feel",
      "type": "multiple_choice",
      "answer_type": "numeric",
      "title": "Overall Feel",
      "min_value": -2,
      "max_value": 1,
      "infoText": "Overall, how comfortable do you find this environment?",
      "options": [
        "Extremely uncomfortable",
        "Uncomfortable",
        "Comfortable",
        "Extremely comfortable"
      ],
      "include_na": true,
      "translation": {
        "es": {
          "title": "Sensación General",
          "infoText": "En general, ¿qué tan cómodo encuentra este entorno?",
          "options": [
            "Extremadamente incómodo",
            "Incómodo",
            "Cómodo",
            "Extremadamente cómodo"
          ]
        },
        "gu": {
          "title": "સરેરાશ અનુભવ",
          "infoText": "આ વાતાવરણ સાથે તમે કેટલા અનુકુળ છો ?",
          "options": [
            "ખુબ જ પ્રતિકુળ",
            "પ્રતિકુળ",
            "અનુકુળ",
            "ખુબ જ અનુકુળ"
          ]
        },
        "hi": {
          "title": "कुल अनुभव",
          "infoText": "कुल मिलाकर, आपको ये वातावरण कितना आरामदायक लगता है?",
          "options": [
            "बहुत असुविधाजनक (बेचैन) / बिल्कुल भी आरामदायक नहीं",
            "आरामदायक नहीं",
            "आरामदायक है",
            "पूरी तरह आरामदायक है"
          ]
        }
      }
    },
    {
      "id": "last_week_sleep",
      "type": "multiple_choice",
      "answer_type": "numeric",
      "title": "Sleep Last 7 Days",
      "min_value": -2,
      "max_value": 2,
      "infoText": "During the past 7 days, your average sleep quality was…",
      "options": [
        "Very poor",
        "Poor",
        "Fair",
        "Good",
        "Very good"
      ],
      "include_na": true,
      "translation": {
        "es": {
          "title": "Sueño en los Últimos 7 Días",
          "infoText": "Durante los últimos 7 días, ¿cómo calificaría su calidad promedio de sueño?",
          "options": [
            "Muy mala",
            "Mala",
            "Regular",
            "Buena",
            "Muy buena"
          ]
        },
        "gu": {
          "title": "છેલ્લા સાત દિવસની ઊંઘ",
          "infoText": "છેલ્લા સાત દિવસ દરમ્યાન તમારી ઊંઘ ની સરેરાશ ગુણવતા કેવી હતી ?",
          "options": [
            "ખુબ જ ખરાબ",
            "ખરાબ",
            "ઠીક",
            "સરસ",
            "ખુબ જ સરસ"
          ]
        },
        "hi": {
          "title": "पिछले 7 दिनों में नींद",
          "infoText": "पिछले 7 दिनों के दौरान आपकी औसतन नींद थी...",
          "options": [
            "बहुत ही कम",
            "कम",
            "ठीक-ठीक",
            "अच्छी",
            "बहुत अच्छी"
          ]
        }
      }
    },
    {
      "id": "last_week_energy",
      "type": "multiple_choice",
      "answer_type": "numeric",
      "title": "Energy Last 7 Days",
      "min_value": 0,
      "max_value": 3,
      "infoText": "During the past 7 days, how do you rate your energy levels",
      "options": [
        "Not at all energetic",
        "Mildly energetic",
        "Moderately energetic",
        "Extremely energetic"
      ],
      "include_na": true,
      "translation": {
        "es": {
          "title": "Energía en los Últimos 7 Días",
          "infoText": "Durante los últimos 7 días, ¿cómo calificaría su nivel de energía?",
          "options": [
            "Nada energético",
            "Ligeramente energético",
            "Moderadamente energético",
            "Extremadamente energético"
          ]
        },
        "gu": {
          "title": "છેલ્લા સાત દિવસની તમારી સ્ફૂર્તિ",
          "infoText": "છેલ્લા સાત દિવસ દરમ્યાન તમે તમારી સ્ફૂર્તિ  કેવી રીતે માપશો ?",
          "options": [
            "બિલકુલ સ્ફૂર્તિ  નથી",
            "થોડીક સ્ફૂર્તિ  છે",
            "થોડીક વધારે સ્ફૂર્તિ  છે",
            "ખુબ જ સ્ફૂર્તિ  છે"
          ]
        },
        "hi": {
          "title": "पिछले 7 दिनों में आपकी शारीरिक शक्ति",
          "infoText": "पिछले 7 दिनों के दौरान आप अपनी शारीरिक शक्ति के स्तर का मूल्यांकन किस तरह करती हैं?",
          "options": [
            "बिल्कुल भी ऊर्जावान नहीं",
            "हल्की ऊर्जावान",
            "माध्यम रूप से ऊर्जावान",
            "बहुत ऊर्जावान"
          ]
        }
      }
    },
    {
      "id": "rupees_last_week",
      "type": "numeric",
      "title": "Rupees Last Week",
      "infoText": "How many rupees (₹) did you make last week?",
      "include_na": true,
      "translation": {
        "es": {
          "title": "Rupias la Semana Pasada",
          "infoText": "¿Cuántas rupias (?) ganó la semana pasada?"
        },
        "gu": {
          "title": "ગયા અઠવાડિયામાં કેટલા રૂપિયા",
          "infoText": "ગયા અઠવાડિયે તમે કેટલા રૂપિયા (₹) કમાયા?"
        },
        "hi": {
          "title": "पिछले हफ्ते में रुपये",
          "infoText": "पिछले हफ्ते आपने कितने रुपये कमाए?"
        }
      }
    },
    {
      "id": "rupees_normal_week",
      "type": "numeric",
      "title": "Rupees in a Normal Week",
      "infoText": "How many rupees (₹) do you normally make in a week?",
      "include_na": true,
      "translation": {
        "es": {
          "title": "Rupias en una Semana Normal",
          "infoText": "¿Cuántas rupias (?) gana normalmente en una semana?"
        },
        "gu": {
          "title": "સામાન્ય અઠવાડિયા ની આવક",
          "infoText": "સામાન્ય રીતે તમે એક અઠવાડિયા માં કેટલા પૈસા કમાઓ છો?"
        },
        "hi": {
          "title": "एक सामान्य हफ्ते में रुपये ",
          "infoText": "आप सामान्यत एक हफ्ते में कितने रुपये कमाती हैं?"
        }
      }
    },
    {
      "id": "last_week_days_off",
      "type": "binary",
      "title": "Worked Less last 7 Days",
      "infoText": "During the past 7 days, have you taken time off or worked less hours due to heat?",
      "yes": "Yes",
      "no": "No",
      "include_na": true,
      "translation": {
        "es": {
          "title": "Menos Trabajo en los Últimos 7 Días",
          "infoText": "¿Durante los últimos 7 días, ha tomado tiempo libre o trabajado menos horas debido al calor?",
          "yes": "Sí",
          "no": "No"
        },
        "gu": {
          "title": "છેલ્લા સાત દિવસમાં ઓછુ કામ કર્યું",
          "infoText": "છેલ્લા સાત દિવસ દરમ્યાન ગરમીના કારણે તમે ઓછા કલાકો કામ કર્યું હોય અથવા રજા લીધી છે ?",
          "yes": "હા",
          "no": "ના"
        },
        "hi": {
          "title": "पिछले 7 दिनों में कम काम किया",
          "infoText": "पिछले 7 दिनों के दौरान, क्या आपने गर्मी के कारण छुट्टी ली या कम घंटे काम किए?",
          "yes": "हाँ",
          "no": "नहीं"
        }
      }
    },
    {
      "id": "last_week_productivity",
      "type": "multiple_choice",
      "answer_type": "numeric",
      "min_value": -2,
      "max_value": 2,
      "title": "Productivity Last 7 Days",
      "infoText": "During the past 7 days, how productive have you been at work?",
      "options": [
        "Much less productive",
        "Less productive",
        "Normal",
        "More productive",
        "Much more productive"
      ],
      "include_na": true,
      "translation": {
        "es": {
          "title": "Productividad en los Últimos 7 Días",
          "infoText": "Durante los últimos 7 días, ¿qué tan productivo ha sido en el trabajo?",
          "options": [
            "Mucho menos productivo",
            "Menos productivo",
            "Normal",
            "Más productivo",
            "Mucho más productivo"
          ]
        },
        "gu": {
          "title": "છેલ્લા સાત દિવસની કાર્યક્ષમતા",
          "infoText": "છેલ્લા સાત દિવસ દરમ્યાન તમે તમારી કામ કરવાની ક્ષમતા કેવી રહી છે ?",
          "options": [
            "કામ કરવાની ક્ષમતા ઘણી ઓછી થઇ ગઈ છે",
            "કામ કરવાની ક્ષમતા થોડીક ઓછી થઇ ગઈ છે",
            "કોઈ અસર નથી",
            "કામ કરવાની ક્ષમતા થોડીક વધી ગઈ છે.",
            "કામ કરવાની ક્ષમતા ઘણી વધી ગઈ છે."
          ]
        },
        "hi": {
          "title": "पिछले 7 दिनों में उत्पादकता",
          "infoText": "पिछले 7 दिनों के दौरान, आप अपने काम में कितने उत्पादक रहे हैं?",
          "options": [
            "बहुत कम उत्पादक रही हूँ",
            "कम उत्पादक रही हूँ",
            "सामान्य उत्पादक रही हूँ",
            "ज्यादा उत्पादक रही हूँ",
            "बहुत ज्यादा उत्पादक रही हूँ"
          ]
        }
      }
    },
    {
      "id": "oral_temperature",
      "type": "custom",
      "title": "Oral Temperature",
      "infoText": "What is the participant's oral temperature? During the oral temperature measurement, the thermometer should be kept under the participants tongue with their mouth closed. Wait until the thermometer beeps before removing the thermometer from the participants mouth.",
      "include_na": true,
      "translation": {
        "gu": {
          "title": "શરીરનું તાપમાન",
          "infoText": "બહેનનું શરીર નું તાપમાન  (°F)  કેટલું છે ? બહેનનું શરીરનું  તાપમાન માપવાના સમયે થર્મોમીટર તેમના જીભ નીચે રાખવું જોઈએ અને મોં બંધ રાખવું જોઈએ. થર્મોમીટર માંથી બીપ નો અવાજ આવે ત્યાં સુધી રાહ જોવી ત્યારબાદ બહેનના મોં માંથી થર્મોમીટર કાઢી લેવું."
        },
        "es": {
          "title": "Temperatura Oral",
          "infoText": "¿Cuál es la temperatura oral del participante? Durante la medición, el termómetro debe mantenerse debajo de la lengua del participante con la boca cerrada. Espere hasta que el termómetro emita un sonido antes de retirarlo."
        },
        "hi": {
          "title": "मौखिक (मुख के द्वारा लीया जाने वाला) तापमान",
          "infoText": "बहन का मौखिक तापमान (°F) क्या है? मौखिक तापमान मापने के दौरान, थर्मामीटर को प्रतिभागियों का मुंह बंद करके जीभ के नीचे रखा जाना चाहिए। बहनों के मुंह से थर्मामीटर हटाने से पहले थर्मामीटर के बीप बजने तक इंतेजार करें।"
        }
      }
    },
    {
      "id": "blood_pressure",
      "type": "custom",
      "title": "Blood Pressure",
      "infoText": "What is the participant's blood pressure? During blood pressure measurements the participant should, if possible, be seated on a comfortable chair with both feet flat on the floor. The arm that the cuff is placed on should be kept at approximately heart (chest) level. While the blood pressure measurements are being taken, there should be no talking or distractions (e.g., people coming in and out of the room).",
      "include_na": true,
      "translation": {
        "gu": {
          "title": "બ્લડ પ્રેશર",
          "infoText": "સભ્યનું બ્લડ પ્રેશર શું છે? બ્લડ પ્રેશર માપવાના સમયે, શક્ય હોય તો આરામદાયક ખુરશી પર બેસાડવું જોઈએ અને બંને પગ જમીન પર સીધા રાખવા જોઈએ. હાથ ને એવી રીતે મુકવો જેથી આપણે બાવડું છાતી ના સ્તરે હોય. જ્યારે બ્લડ પ્રેશર માપવામાં આવી રહ્યો છે, ત્યારે કોઈ વાતચીત કે અતિશય હલન-ચલન કરવું નહીં."
        },
        "es": {
          "title": "Presión Arterial",
          "infoText": "¿Cuál es la presión arterial del participante? Durante la medición, el participante debe, si es posible, estar sentado en una silla cómoda con ambos pies planos en el suelo. El brazo donde se coloca el brazalete debe mantenerse a nivel del corazón (pecho). Durante la medición, no debe haber conversaciones ni distracciones (por ejemplo, personas entrando o saliendo de la habitación)."
        },
        "hi": {
          "title": "ब्लड प्रेशर/रक्त चाप",
          "infoText": "बहन का रक्त चाप क्या/कितना है? रक्त चाप मापने के दौरान, अगर संभव हो तो बहन को एक आरामदायक कुर्सी पर दोनों पैरों को फर्श पर सपाट करके बैठाना चाहिए। जिस बांह पर कफ रखा जाए उसे लगभग हृदय (छाती) के स्तर पर रखा जाना चाहिए। जब रक्तचाप माप लीया जा रहा हो, तो कोई बातचीत या ध्यान भटकना नहीं चाहिए। (उदाहरण के लिए, कमरे के अंदर और बाहर लोगों का आना-जाना।)"
        }
      }
    }
  ]
} 
export default CONFIG