import Header from '../components/Header';
import * as locCon from '../LocalConstants';
import CustomButton, { CustomDangerButtonWithDisability } from '../elements/CustomButton';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import { ModalHook } from '../hooks/ModalHooks';
import { DeviceStatusReducerHook, resetATObject } from '../hooks/BundleDeviceHooks';
import * as libCon from '../community-hats-js-library/Constants';
import { BundleSyncProgress } from '../elements/SyncATOButton';
import { useState } from 'react';
import { endPlacement } from '../community-hats-js-library/utils/airtableFunctions';
import { getATOId, getATOIdInsideSet, getPositionIds, RefStoredBooleanValue, setMultipleCheckBoxes, setMultipleValues } from '../hooks/StoreHooks';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import CheckboxGroup from '../elements/CheckboxGroup';


export function BundleRetrieve() {

  // Language
  const i18n = RefLanguage()

  // Modal
  const [modal, openModal] = ModalHook()

  // Mesage
  const [messages, setMessages] = useState([])

  // Checknoxes
  const collectCheckBox = RefStoredBooleanValue(locCon.RB_COLLECT_DATA)
  const checkCheckBox = RefStoredBooleanValue(locCon.RB_CHECK_BUNDLE)

  const [objectStatus, updateAll, updateSingle] = DeviceStatusReducerHook()


  const startAll = () => updateAll(libCon.STATUS_LOADING)


  const retrieveBundleLocal = async () => {

    let status, records, placementId

    // Starts
    startAll();

    let checkBoxes = []


    // Participant Placement
    placementId = getATOId(locCon.AT_OBJECT_PARTICIPANT_PLACEMENT)
    if (!isNullOrUndefined(placementId)) {
      [status, records] = await endPlacement(await libCon.CONFIG(locCon.AT_OBJECTS_CONFIGS[locCon.AT_OBJECT_PARTICIPANT_PLACEMENT][libCon.TABLE_CONFIG_KEY]), placementId)

      if (status === libCon.OK && Object.values(records).length > 0) // Unecessary and but used to remove the warning
      {
        updateSingle(locCon.AT_OBJECT_PARTICIPANT_PLACEMENT, libCon.STATUS_OK)
        resetATObject(locCon.AT_OBJECT_PARTICIPANT_PLACEMENT)
        checkBoxes.push(locCon.APT_ADD_PARTICIPANT_TO_DATABASE)
      }
      else
        updateSingle(locCon.AT_OBJECT_PARTICIPANT_PLACEMENT, libCon.STATUS_ERROR)

    }
    else // No placement was found
    {
      updateSingle(locCon.AT_OBJECT_PARTICIPANT_PLACEMENT, libCon.STATUS_OK)
      checkBoxes.push(locCon.APT_ADD_PARTICIPANT_TO_DATABASE)
    }



    // Phone Placement
    placementId = getATOId(locCon.AT_OBJECT_PHONE_PLACEMENT)
    if (!isNullOrUndefined(placementId)) {
      [status, records] = await endPlacement(await libCon.CONFIG(locCon.AT_OBJECTS_CONFIGS[locCon.AT_OBJECT_PHONE_PLACEMENT][libCon.TABLE_CONFIG_KEY]), placementId)

      if (status === libCon.OK) {
        updateSingle(locCon.AT_OBJECT_PHONE_PLACEMENT, libCon.STATUS_OK)
        resetATObject(locCon.AT_OBJECT_PHONE_PLACEMENT)
        checkBoxes.push(locCon.APPD_PHONE_PLACEMENT)
      }
      else
        updateSingle(locCon.AT_OBJECT_PHONE_PLACEMENT, libCon.STATUS_ERROR)

    }
    else // No placement was found
    {
      updateSingle(locCon.AT_OBJECT_PHONE_PLACEMENT, libCon.STATUS_OK)
      checkBoxes.push(locCon.APPD_PHONE_PLACEMENT)
    }


    // Wearable Placement
    placementId = getATOId(locCon.AT_OBJECT_WEARABLE_PLACEMENT)
    if (!isNullOrUndefined(placementId)) {
      [status, records] = await endPlacement(await libCon.CONFIG(locCon.AT_OBJECTS_CONFIGS[locCon.AT_OBJECT_WEARABLE_PLACEMENT][libCon.TABLE_CONFIG_KEY]), placementId)

      if (status === libCon.OK) {
        updateSingle(locCon.AT_OBJECT_WEARABLE_PLACEMENT, libCon.STATUS_OK)
        resetATObject(locCon.AT_OBJECT_WEARABLE_PLACEMENT)
        checkBoxes.push(locCon.AWP_WEARABLE_PLACEMENT)
      }
      else
        updateSingle(locCon.AT_OBJECT_WEARABLE_PLACEMENT, libCon.STATUS_ERROR)

    }
    else // No placement was found
    {
      updateSingle(locCon.AT_OBJECT_WEARABLE_PLACEMENT, libCon.STATUS_OK)
      checkBoxes.push(locCon.AWP_WEARABLE_PLACEMENT)
    }



    // Sensor Placement
    let positionIds = getPositionIds()


    let sensorPlaceentsStatus = await Promise.all(positionIds.map(async (positionId) => {

      const sensorPlacementId = getATOIdInsideSet(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT)

      if (!isNullOrUndefined(sensorPlacementId)) {


        [status, records] = await endPlacement(await libCon.CONFIG(locCon.AT_OBJECTS_CONFIGS[locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT][libCon.TABLE_CONFIG_KEY]), sensorPlacementId)


        if (status === libCon.OK) {
          resetATObject(locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT)
          checkBoxes.push(locCon.getASHPCheckBoxId(positionId))
          return libCon.STATUS_OK
        }
        else
          return libCon.STATUS_ERROR

      }
      else // No placement was found
      {
        checkBoxes.push(locCon.getASHPCheckBoxId(positionId))
        return libCon.STATUS_OK

      }

    }))


    if (sensorPlaceentsStatus.every(s => s === libCon.STATUS_OK))
      updateSingle(locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.STATUS_OK)
    else
      updateSingle(locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.STATUS_ERROR)


    setMultipleCheckBoxes(checkBoxes, false)

    setMessages([i18n.t("retreiveCorrectly")])

    // TODO
    // Include messages when procedure fails

  }


  const confirmProcess = () => {


    openModal(i18n.t("retreiveBundle"), i18n.t("bundleRetreiveLong"), [
      {
        text: i18n.t("cancel"),
        onClick: () => false,
        type: locCon.MODULE_CANCEL_BUTTON,
      },
      {
        text: i18n.t("yes"),
        onClick: () => retrieveBundleLocal(),
        type: locCon.MODULE_REGULAR_BUTTON
      },
    ]);



  }


  const restartProcess = () => {

    let checkBoxValues = {
      [locCon.RB_CHECK_BUNDLE]: false,
      [locCon.RB_COLLECT_DATA]: false,
    }

    setMultipleValues(checkBoxValues)
    updateAll(libCon.STATUS_NOT_STARTED)



  }


  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>
        {modal}

        <Header long={false} />
        <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("retreiveBundle")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToRetreive")}</CustomText>

        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartProcess")}</CustomButton>

        <div className="horizontalLine" />

        {/* Collect Data and  Check bundel*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("collectAndCheckBundle")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("collectAndCheckBundleText")}</CustomText>
        <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.RB_COLLECT_DATA, [libCon.TEXT]: i18n.t("collectData") }} />
        <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.RB_CHECK_BUNDLE, [libCon.TEXT]: i18n.t("checkBundleStatus") }} />

        <div className="horizontalLine" />


        <CustomDangerButtonWithDisability isDisabled={!collectCheckBox || !checkCheckBox} title={i18n.t("pleaseCollectAndCheck")} onClick={() => confirmProcess()}>{i18n.t("retreiveBundle")}</CustomDangerButtonWithDisability>


        <BundleSyncProgress text={i18n.t("participantPlacement")} status={objectStatus[locCon.AT_OBJECT_PARTICIPANT_PLACEMENT]} />
        <BundleSyncProgress text={i18n.t("wearablePlacement")} status={objectStatus[locCon.AT_OBJECT_WEARABLE_PLACEMENT]} />
        <BundleSyncProgress text={i18n.t("phonePlacement")} status={objectStatus[locCon.AT_OBJECT_PHONE_PLACEMENT]} />
        <BundleSyncProgress text={i18n.t("sensorHausePlacement")} status={objectStatus[locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT]} />


        <div className='verticalSection' style={{ width: "80%", marginTop: "2vh" }}>
          {
            messages.map((mess, k) => <CustomText key={k} type={locCon.INFO_TEXT} style={{ marginBottom: "1vh" }}>{mess}</CustomText>)
          }
        </div>

      </div>
    </div>
  );
}

export default BundleRetrieve


