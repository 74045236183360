import React, { useEffect, useState } from 'react'
import * as locCon from '../LocalConstants';
import { RefLanguage } from '../hooks/LanguageHooks';
import CustomText from './CustomText';
import { RefStoredMultipleValues } from '../hooks/StoreHooks';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { BackButtonLarge } from './BackButton';


function CompletionStatusText({ checkBoxIds, extraMessage = null, includeBackButton = true }) {

    const i18n = RefLanguage()


    const currentCheckBoxesValues = RefStoredMultipleValues(checkBoxIds)
    const [finished, setFinished] = useState(false)


    useEffect(() => {

        setFinished(Object.values(currentCheckBoxesValues).every(val => val === true))



    }, [currentCheckBoxesValues])


    return (
        finished
            ?
            <div className='verticalSection' style={{ marginBottom: "2vh" }}>
                <CustomText type={locCon.SUCCESS_TEXT}>{i18n.t("processCompletedSuccesfully")}</CustomText>
                {!isNullOrUndefined(extraMessage) ? <CustomText type={locCon.SUCCESS_TEXT}>{extraMessage}</CustomText> : <div></div>}
                {includeBackButton ? <BackButtonLarge /> : <div></div>}
            </div>
            : <div className='verticalSection'>
                <CustomText type={locCon.DANGER_TEXT}>{i18n.t("processNotFinished")}
                </CustomText></div>
    )
}

export default CompletionStatusText