import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { RefLanguage } from '../hooks/LanguageHooks'
import CustomText from './CustomText'
import * as locCon from "../LocalConstants"
import * as locGenFun from "../utils/generalFunctions"
import { CaretDownOutlined } from '@ant-design/icons'
import { StateATOField, StateATOInsideSetField, StateStoredValue } from '../hooks/StoreHooks'
import CustomButton from './CustomButton'
import { CheckboxDefaultValue } from './CustomCheckBox'
import { SimpleStoreCopyField } from './SimpleStoreCopyField'
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions'




const OptionsModal = ({ isVisible, setIsVisible, selectFunction, options, optionsTextDict, includeOther }) => {

    const i18n = RefLanguage()

    const [other, setOther] = useState(null)


    return (<Modal
        style={{ right: 15 }}
        open={isVisible}
        title={i18n.t("options")}
        onCancel={() => setIsVisible(false)}
        footer={<></>}>
        <div className='verticalSection'>
            {
                options.map((id, i) => <Button key={i} style={{ width: "100%", marginTop: 7, marginBottom: 7, whiteSpace: 'normal', height: 'auto' }} onClick={() => selectFunction(id)}>
                    <span>{optionsTextDict[id]}</span>
                </Button>)
            }
            {
                includeOther
                    ? <SimpleStoreCopyField value={other} setValue={setOther} editingValue={true} setEditingValue={() => true} enableCopy={false} placeHolder={i18n.t("other")} saveCleanFunction={(isEditing, value) => selectFunction(value)} />
                    : <div></div>

            }


        </div>
    </Modal>)

}




export function SimpleStoreCopySelectionField({ title, value, setValue, options, optionsTextDict, infoText = null, enableCopy = true, includeCheckbox = false, defaultValue, checkboxText, includeOther = false, maxLength = 35, onClickCleanup = (val) => val }) {


    const [isVisible, setIsVisible] = useState(() => false)

    const i18n = RefLanguage()

    const [answered, setAnswered] = useState(() => true)

    const localSetValue = (val) => {
        setValue(val)
        onClickCleanup(val)
    }


    useEffect(() => {
        setAnswered(value in optionsTextDict)
    }, [value, optionsTextDict])





    return (
        <div className='verticalSection'>
            {title !== null ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
            {infoText !== null ? <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText> : <div></div>}

            <OptionsModal isVisible={isVisible} setIsVisible={setIsVisible} includeOther={includeOther} selectFunction={(id) => { localSetValue(id); setIsVisible(false) }} options={options} optionsTextDict={optionsTextDict} />
            <div className='horizontalSectionCenter' style={{ marginBottom: "1vh" }}>
                {enableCopy ? <CustomButton type={answered ? locCon.SAVE_EDIT_BUTTON : locCon.SAVE_EDIT_BUTTON_DISABLED} onClick={() => answered ? locGenFun.copyToClipboard(optionsTextDict[value], `Value copied to clipboard`) : false}>{i18n.t("copy")}</CustomButton> : <div></div>}
                <div className="horizontalSection" onClick={() => setIsVisible(true)} style={{ height: 40, width: "100%", backgroundColor: "var(--background-color-2)", borderRadius: "25px", marginRight: "2%" }}>
                    <div style={{ width: "85%", textAlign: 'center' }}>
                        <CustomText type={locCon.ELEMENT_TEXT}>{value in optionsTextDict ? `${optionsTextDict[value].slice(0, Math.min(maxLength, optionsTextDict[value].length))}${optionsTextDict[value].length > maxLength ? '...' : ''}` : includeCheckbox && value === defaultValue ? defaultValue : !isNullOrUndefined(value) ? value : i18n.t("selectOptions")}</CustomText>
                    </div>
                    <CaretDownOutlined style={{ color: "black", fontSize: 25, marginRight: 10 }} />
                </div >
            </div>
            {includeCheckbox ? <CheckboxDefaultValue value={value} setValue={localSetValue} defaultValue={defaultValue} text={checkboxText} /> : <div></div>}
        </div>

    )
}

export function SimpleStoreCopySelectionFieldFromATO({ atoId, fieldId, ...props }) {

    const [value, setValue] = StateATOField(atoId, fieldId)

    return (
        <SimpleStoreCopySelectionField value={value} setValue={setValue} {...props} />);
}

export function SimpleStoreCopySelectionFieldFromATOInsideSet({ positionId, atoId, fieldId, ...props }) {

    const [value, setValue] = StateATOInsideSetField(positionId, atoId, fieldId)

    return (
        <SimpleStoreCopySelectionField value={value} setValue={setValue} {...props} />);
}

export function SimpleStoreCopySelectionFieldFromId({ valueID, ...props }) {

    const [value, setValue] = StateStoredValue(valueID)

    return (
        <SimpleStoreCopySelectionField value={value} setValue={setValue} {...props} />
    );
}