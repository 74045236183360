import Header from '../components/Header';
import * as locCon from '../LocalConstants';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import { RefSetCurrentPage } from '../hooks/NavigationHooks';
import CustomButton from '../elements/CustomButton';


export function BundleManage() {

  const i18n = RefLanguage()
  // Current Page
  const setCurrentPage = RefSetCurrentPage()


  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>

        <Header long={true} />

        {/* Wearable*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("wearable")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_MANAGE_BUNDLE_REPLACE_WEARABLE)}>{i18n.t("replaceWearable")}</CustomButton>
        <div className={"horizontalLine"} />

        {/* Sensors*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("sensors")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_MANAGE_BUNDLE_ADD_SENSOR)}>{i18n.t("addSensor")}</CustomButton>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_MANAGE_BUNDLE_MOVE_SENSOR)}>{i18n.t("moveSensor")}</CustomButton>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_MANAGE_BUNDLE_REMOVE_SENSOR)}>{i18n.t("removeSensor")}</CustomButton>
        <div className={"horizontalLine"} />


        {/* House*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("house")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_MANAGE_BUNDLE_CHANGE_HOUSE)}>{i18n.t("changeHouse")}</CustomButton>
        <div className={"horizontalLine"} />

        {/* Email*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("email")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_MANAGE_BUNDLE_REPLACE_EMAIL)}>{i18n.t("replaceEmail")}</CustomButton>
        <div className={"horizontalLine"} />

        {/* Complete Bundle */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("completeBundle")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_BUNDLE_RETRIEVE)}>{i18n.t("retreiveBundle")}</CustomButton>
        <div className={"horizontalLine"} />


      </div>
    </div>
  );
}

export default BundleManage


