import React from 'react'
import { RefLanguage } from '../hooks/LanguageHooks';
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
// import * as genFun from '../community-hats-js-library/utils/generalFunctions';
import Header from '../components/Header';
import CustomText from '../elements/CustomText';
import CustomButton from '../elements/CustomButton';
import { RefStoredBooleanValue, setATOField, setMultipleValues, setStoredValue } from '../hooks/StoreHooks';
import CheckboxGroup from '../elements/CheckboxGroup';
import { EndAndRemovePlacementButton, RemoveElementButton, SyncATOButton } from '../elements/SyncATOButton';
import CompletionStatusText from '../elements/CompletionStatusText';
import RandomEmailAccountGenerator from '../components/RandomEmailAccountGenerator';
import { goToThirdPartyLink } from '../community-hats-js-library/utils/generalFunctions';
import { buildFitbitURL } from '../community-hats-js-library/utils/fitbitFunctions';
import PasteFitbitAPIValues from '../components/PasteFitbitAPIValues';
import { SimpleCopyFieldFromId } from '../elements/SimpleStoreCopyField';
import TestFitibitApiButton from '../components/TestFitibitApiButton';
import { showNotification } from '../utils/generalFunctions';

function ReplaceEmail() {

    const i18n = RefLanguage()


    // Local checkboxes
    const isEmailReplaced = RefStoredBooleanValue(locCon.RE_REPLACE_EMAIL)


    const allCheckboxes = [
        locCon.RE_COLLECT_DATA,
        locCon.RE_END_AND_REMOVE_WEARABLE_PLACEMENT,
        locCon.RE_SET_UP_NEW_DEVICE,
        locCon.RE_WEARABLE_PLACEMENT,
        locCon.RE_UPDATE_BUNDLE_TO_DATABASE,
        locCon.RE_REMOVE_EMAIL_FROM_DEVICE,
        locCon.RE_REMOVE_EMAIL_FROM_BUNDLE,
        locCon.RE_FTB_SET_API,
        locCon.RE_FTB_GO_TO_AUTHORIZATION_PAGE,
        locCon.RE_FTB_COPY_TOKEN_AND_REQUEST,
        locCon.RE_FTB_TEST_TOKEN,
        locCon.RE_ADD_EMAIL_TO_DATABASE,
        locCon.RE_SET_UP_GOOGLE_ACCOUNT
    ]

    const restartProcess = () => {

        let checkBoxValues = {}

        allCheckboxes.forEach(k => checkBoxValues[k] = false)

        setMultipleValues(checkBoxValues)

        showNotification(i18n.t("processRestarted"))


    }


    return (
        <div className="mainAppContainer">
            <div className='pageContainer'>

                <Header long={false} />
                <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("replaceEmail")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToReplaceEmail")}</CustomText>

                <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartProcess")}</CustomButton>

                <div className='horizontalLine' />

                {/* Collect Data and  Check bundel*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("collectAndCheckWearable")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("collectAndCheckWearableText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.RE_COLLECT_DATA, [libCon.TEXT]: i18n.t("collecWearabletData") }} />

                <div className='horizontalLine' />

                {/* End and remove wearable placement from Database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("endWearablPalcement")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("endWearablPalcementText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.RE_END_AND_REMOVE_WEARABLE_PLACEMENT,
                    [libCon.TEXT]: i18n.t("endWearablPalcement"),
                    [libCon.CONTAINER]: <EndAndRemovePlacementButton placementATOid={locCon.AT_OBJECT_WEARABLE_PLACEMENT} onClickCleanup={(val) => { setStoredValue(locCon.RE_END_AND_REMOVE_WEARABLE_PLACEMENT, val); setATOField(locCon.AT_OBJECT_WEARABLE, libCon.ATF_SERIAL, null) }} forceSuccessText={isEmailReplaced} />
                }} />

                <div className="horizontalLine" />

                {/* Remove the Google Account From Bundle*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("removeEmailAccountFromBundle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("removeEmailAccountInstructionsFromBundle")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.RE_REMOVE_EMAIL_FROM_BUNDLE,
                    [libCon.TEXT]: i18n.t("removeEmailAccountFromBundle"),
                    [libCon.CONTAINER]: <RemoveElementButton elementATOId={locCon.AT_OBJECT_EMAIL_ACCOUNT} onClickCleanup={(val) => { setStoredValue(locCon.RE_REMOVE_EMAIL_FROM_BUNDLE, val); }} forceSuccessText={isEmailReplaced} />
                }} />

                <div className="horizontalLine" />

                {/* Remove the Google Account From Device*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("removeEmailAccountFromDevice")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("removeEmailAccountInstructionsFromDevice")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.RE_REMOVE_EMAIL_FROM_DEVICE, [libCon.TEXT]: i18n.t("removeEmailAccountFromDevice") }} />

                <div className="horizontalLine" />

                {/* Google Account*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("googleAccount")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("googleAccountText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.RE_SET_UP_GOOGLE_ACCOUNT, [libCon.TEXT]: i18n.t("setUpGoogleAccount"), [libCon.CONTAINER]: <RandomEmailAccountGenerator includeCheckBox={true} /> }} />
                <div className="horizontalLine" />


                {/* Fitbit set up */}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("addFitBit")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("addFitBitText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.RE_SET_UP_NEW_DEVICE, [libCon.TEXT]: i18n.t("addFitBit") }} />


                <div className="horizontalLine" />


                {/* Fitbit API access*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("fitbitApiAccess")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("fitBitAPITextShort")}</CustomText>
                <CheckboxGroup
                    mainCheckbox={{ [libCon.ID]: locCon.RE_FTB_SET_API, [libCon.TEXT]: i18n.t("setUpFitbitAPI") }}
                    checkBoxArray={[
                        {
                            [libCon.ID]: locCon.RE_FTB_GO_TO_AUTHORIZATION_PAGE,
                            [libCon.TEXT]: i18n.t("goToAuthPage"),
                            [libCon.CONTAINER]: <div>
                                <CustomButton type={locCon.PRIMARY_BUTTON} onClick={async () => { goToThirdPartyLink(buildFitbitURL(await libCon.CONFIG(libCon.FITBIT_FINAL_APPLICATION_ID))); setStoredValue(locCon.RE_FTB_GO_TO_AUTHORIZATION_PAGE, true) }}>
                                    {i18n.t("authorizeApp")}
                                </CustomButton>
                            </div>
                        },
                        {
                            [libCon.ID]: locCon.RE_FTB_COPY_TOKEN_AND_REQUEST,
                            [libCon.TEXT]: <div>{i18n.t("copyTokenAndRequest")}</div>,
                            [libCon.CONTAINER]: <div>
                                <PasteFitbitAPIValues checkboxId={locCon.RE_FTB_COPY_TOKEN_AND_REQUEST} />
                                <SimpleCopyFieldFromId valueID={locCon.FITBIT_TOKEN} title={i18n.t("fitbitAccessToken")} />
                                <SimpleCopyFieldFromId valueID={locCon.FITBIT_REQUEST_ID} title={i18n.t("fitbitRequestId")} />
                            </div>
                        },
                        {
                            [libCon.ID]: locCon.RE_FTB_TEST_TOKEN,
                            [libCon.TEXT]: <div>{i18n.t("testFitbitApi")}</div>,
                            [libCon.CONTAINER]: <TestFitibitApiButton checkboxId={locCon.RE_FTB_TEST_TOKEN} />
                        },


                    ]}
                />


                <div className="horizontalLine" />

                {/* Add Email to database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("emailToDataBaseTitle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("emailToDataBaseText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.RE_ADD_EMAIL_TO_DATABASE,
                    [libCon.TEXT]: i18n.t("addEmailToDataBase"),
                    [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_EMAIL_ACCOUNT} atoBehavior={libCon.ATO_BEHAVIOR_UPDATE_CREATE} onClickCleanup={(val) => setStoredValue(locCon.RE_ADD_EMAIL_TO_DATABASE, val)} />
                }} />



                <div className="horizontalLine" />


                {/* Add / Sync Bundle to database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("updateBundle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("updateBundleText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.RE_UPDATE_BUNDLE_TO_DATABASE,
                    [libCon.TEXT]: i18n.t("updateBundle"),
                    [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_BUNDLE} atoBehavior={libCon.ATO_BEHAVIOR_UPDATE_CREATE} onClickCleanup={(val) => setStoredValue(locCon.RE_UPDATE_BUNDLE_TO_DATABASE, val)} />
                }} />

                <div className="horizontalLine" />


                {/* Add wearable placement to Database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("wearablePlacementToDataBaseTitle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("wearablePlacementToDataBaseText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.RE_WEARABLE_PLACEMENT,
                    [libCon.TEXT]: i18n.t("wearablePlacementToDataBase"),
                    [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_WEARABLE_PLACEMENT} atoBehavior={libCon.ATO_BEHAVIOR_CREATE} onClickCleanup={(val) => setStoredValue(locCon.RE_WEARABLE_PLACEMENT, val)} />
                }} />
                <div className="horizontalLine" />


                <CompletionStatusText checkBoxIds={allCheckboxes} />

                <div className="horizontalLine" />


            </div>
        </div>
    );
}

export default ReplaceEmail