const CONFIG = {
  "metadata": {
    "version": "2025.03.07"
  },
  "structure": [
    {
      "id": "age",
      "type": "numeric",
      "title": "Participant's Age",
      "min_value": 18,
      "max_value": 80,
      "infoText": "Enter the participant age (in years)",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "ઉંમર (વર્ષ)",
          "infoText": "બહેનની ઉંમર (આકડામાં)"
        },
        "hi": {
          "title": "उम्र (साल)",
          "infoText": "बहन कि उम्र लिखें (साल में)।"
        },
        "es": {
          "title": "Edad de la participante",
          "infoText": "Ingrese la edad de la participante (en años)"
        }
      }
    },
    {
      "id": "height",
      "type": "numeric",
      "precision": 1,
      "min_value": 110,
      "max_value": 190,
      "title": "Participant's Height",
      "infoText": "Enter the participants measured height in cm (1 inch = 2.54 cm)",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "ઊંચાઈ (સેમી)",
          "infoText": "બહેનની ઊંચાઈ સેમીમાં  (1 ઇંચ = 2.54 સેમી)"
        },
        "hi": {
          "title": "ऊँचाई (सेमी)",
          "infoText": "बहन कि ऊँचाई सेमी में लिखें (1इंच = 2.54सेमी)।"
        },
        "es": {
          "title": "Altura de la participante",
          "infoText": "Ingrese la altura medida de la participante en cm (1 pulgada = 2.54 cm)"
        }
      }
    },
    {
      "id": "weight",
      "type": "numeric",
      "precision": 1,
      "min_value": 30,
      "max_value": 130,
      "title": "Participant's Weight",
      "infoText": "Enter the participants measured weight in kilograms (1 kg = 2.2 pounds). Enter the number to 1 decimal places if possible.",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "વજન (કિગ્રા)",
          "infoText": "બહેનનું નું માપવામાં આવેલ વજન કિલોગ્રામમાં લખો (1 કિલોગ્રામ = 2.2 પાઉન્ડ) શક્ય હોય તો બે. જો શક્ય હોય તો નંબરને 1 દશાંશ સ્થાન સુધી દાખલ કરો."
        },
        "hi": {
          "title": "वजन (किग्रा)",
          "infoText": "बहन का वजन किग्रा में लिखें (1किग्रा = 2.2पाउंड)। यदी संभव हो तो संख्या को दशमलव के दो स्थानों तक दर्ज करें।"
        },
        "es": {
          "title": "Peso de la participante",
          "infoText": "Ingrese el peso medido de la participante en kilogramos (1 kg = 2.2 libras). Ingrese el número con 1 decimal si es posible."
        }
      }
    },
    {
      "id": "comorbities",
      "type": "multiple_choice_answer",
      "title": "Comorbidities and health conditions ",
      "infoText": "Which health conditions or comorbidities does the participant currently have? Select all that apply.",
      "options": [
        "Cardiovascular disease (e.g., coronary artery disease, previous heart attack or stroke)",
        "High blood pressure (hypertension)",
        "Low blood pressure (hypotension)",
        "Diabetes (Type 1 or 2)",
        "Asthma or other respiratory conditions"
      ],
      "include_na": false,
      "translation": {
        "gu": {
          "title": "સહપ્રતિકારિતાઓ (બહેનોને ૨ થી વધારે બીમારીઓ હોય ) અને આરોગ્ય સ્થિતિઓ",
          "infoText": "દર્દી માં બે અથવા વધારે રોગની હાજરી અને બહેનને હાલમાં કઈ બીમારી છે? નીચે આપેલ તમામ બીમારી હોય તો બધાજ વિકલ્પ પસંદ કરો.",
          "options": [
            "હદય સંબધિત બીમારીઓ  (જેમ કે, કોરોનરી આર્ટરી ડિસીઝ, અગાઉનો હાર્ટ એટેક અથવા સ્ટ્રોક)",
            "ઉચ્ચ રક્તચાપ (હાયપરર્ટેન્શન)",
            "નીચું રક્તચાપ (હિપોટેન્શન)",
            "ડાયાબીટીસ / મધુમેહ (ટાઈપ 1 અથવા 2)",
            "અસ્થમા અથવા અન્ય શ્વાસ સંબધિત બીમારીઓ ",
            "કોઈ નહીં (બહેનને કોઈ આરોગ્ય સ્થિતિઓ નથી)"
          ]
        },
        "hi": {
          "title": "सह-रुग्णताएं (व्यक्ति को 2 या उससे अधिक बीमारियाँ होना) एवं आरोग्य स्थितियां",
          "infoText": "बहन को वर्तमान में कौनसी स्वास्थ स्थितियां एवं सह-रुग्णताएं हैं? जो भी लागू हो उसे चुनें।",
          "options": [
            "हृदय संबंधी रोग (कोरोनरी आरट्री डीज़ीज़/ दिल की धमनी का रोग, पिछला दिल का दौरा या स्ट्रोक)",
            "उच्च रक्तचाप/ हाई बी. पी. (हाइपरटेंशन)",
            "लो बी. पी. (हाइपोटेंशन)",
            "डाइबिटीज़/ मधुमेह (टाइप 1 या 2)",
            "अस्थमा या अन्य श्वास संबंधित दिक्कतें"
          ]
        },
        "es": {
          "title": "Comorbilidades y condiciones de salud",
          "infoText": "¿Qué condiciones de salud o comorbilidades tiene actualmente la participante? Seleccione todas las que correspondan.",
          "options": [
            "Enfermedad cardiovascular (por ejemplo, enfermedad arterial coronaria, infarto previo o accidente cerebrovascular)",
            "Presión arterial alta (hipertensión)",
            "Presión arterial baja (hipotensión)",
            "Diabetes (Tipo 1 o 2)",
            "Asma u otras condiciones respiratorias"
          ]
        }
      }
    },
    {
      "id": "trade",
      "type": "multiple_choice",
      "title": "Participant's Trade",
      "answer_type": "string",
      "infoText": "In which trade is the participant currently working? ",
      "options": [
        "Garment stitching",
        "Kite making",
        "Bangle Factory",
        "Agriculture",
        "Street Vendor"
      ],
      "include_na": false,
      "translation": {
        "gu": {
          "title": "રોજગાર વ્યપાર",
          "infoText": "બહેન હાલમાં ક્યાં વ્યવસાયમાં કામ કરી રહ્યા છે ?",
          "options": [
            "વસ્ત્ર સિલાઈ",
            "પતંગ બનાવવી",
            "ચૂડી કારખાનું",
            "કૃષિ",
            "સડક વિક્રેતા"
          ]
        },
        "hi": {
          "title": "रोज़गार व्यापार",
          "infoText": "वर्तमान में बहन कौनसे व्यवसाय में काम कर रही हैं?",
          "options": [
            "कपड़ों कि सिलाई",
            "पतंगें बनाना",
            "चूड़ी कारखाना",
            "कृषि",
            "सड़क विक्रेता"
          ]
        },
        "es": {
          "title": "Oficio de la participante",
          "infoText": "¿En qué oficio está trabajando actualmente la participante?",
          "options": [
            "Costura de prendas",
            "Fabricación de cometas",
            "Fabrica de Bengales",
            "Agricultura",
            "Vendedor ambulante"
          ]
        }
      }
    },
    {
      "id": "months_working",
      "type": "numeric",
      "min_value": 0,
      "max_value": 480,
      "title": "Months in Trade",
      "infoText": "How many months has the participant been working in their current trade? 12 months = 1 year ",
      "include_na": false,
      "translation": {
        "gu": {
          "title": "વેપારમાં મહિનાઓ",
          "infoText": "બહેન હાલમાં જે વ્યવસાયમાં કામ કરી રહ્યા છે તેમાં કેટલા મહિનાથી કામ કરે છે ? 12 મહિના = 1 વર્ષ"
        },
        "hi": {
          "title": "व्यापार में महीने ",
          "infoText": "वर्तमान व्यवसाय में बहन कितनें महीनों से काम कर रही हैं? (12 महीने = 1 साल)"
        },
        "es": {
          "title": "Meses en el oficio",
          "infoText": "¿Cuántos meses ha estado trabajando la participante en su oficio actual? 12 meses = 1 año"
        }
      }
    },
    {
      "id": "hours_day_working",
      "type": "numeric",
      "min_value": 1,
      "max_value": 20,
      "title": "Hours Spent Working",
      "infoText": "How many hours each day does the participant normally work at their current job/trade?",
      "include_na": false,
      "translation": {
        "gu": {
          "infoText": "બહેન હાલમાં જે વ્યવસાયમાં કરી રહ્યા છે તેમાં દરરોજના કેટલા કલાક કામ કરે છે ?"
        },
        "hi": {
          "title": "काम करते हुए बिताए गए घंटे ",
          "infoText": "बहन अपनी वर्तमान नौकरी/व्यापार में सामान्यतः प्रत्येक दिन कितने घंटे काम करती है?"
        },
        "es": {
          "title": "Horas trabajadas",
          "infoText": "¿Cuántas horas al día trabaja normalmente la participante en su trabajo/oficio actual?"
        }
      }
    }
  ]
} 
export default CONFIG