import { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import { getHouse, getIssuesByIds, getParticipant, getPendingQuestionsHouseIntakeForm, getPendingQuestionsParticipantIntakeForm, getPhoneBySerial, getReceivedSensorFilesBySerial, getSensorBySerial, updateAccountLastSynched } from '../community-hats-js-library/utils/airtableFunctions';
import { store } from '../store/store';
import * as types from "../store/types"
import { filterObjectByFunction, isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { getStoredValue, RefATObjectInsideSetFields, RefATOField, RefATOInsideSetField, RefKeysOfATOSet, RefStoredNested, RefStoredValue, setATObject, setATObjectInsideSet, setStoredNestedValue, setStoredValue } from './StoreHooks';
import { getExtraRequestStatus, getIntakeFormStatus, getIssuesStatus, getPerceptualSurveyStatus, getPhonePlacementStatus, getSensorPlacementStatus, getWearablePlacementStatus } from '../community-hats-js-library/utils/devicesStatusFunctions';
import { nowISO } from '../community-hats-js-library/utils/dateFunctions';
import { getUpdatedWearableCoverage } from '../community-hats-js-library/utils/serverFunctions';
import { getLastSync, getLatestHeartRateTimestamp } from '../community-hats-js-library/utils/fitbitFunctions';


export function RefBundleDevices() {

    const setOfObjects = useSelector(state => state[locCon.AT_SET_OF_OBJECTS])

    return (Object.values(setOfObjects).map(ob => ob[locCon.AT_OBJECT_SENSOR]));
}

export const getCurrentSensorSerials = (onlySynched = false) => {

    let setOfObjects = store.getState()[locCon.AT_SET_OF_OBJECTS]

    const serials = Object.fromEntries(Object.keys(setOfObjects).filter(k => locCon.AT_OBJECT_SENSOR in setOfObjects[k]
        && libCon.FIELDS in setOfObjects[k][locCon.AT_OBJECT_SENSOR]
        && !isNullOrUndefined(setOfObjects[k][locCon.AT_OBJECT_SENSOR][libCon.FIELDS][libCon.ATF_SERIAL])
        && (!onlySynched || !isNullOrUndefined(setOfObjects[k][locCon.AT_OBJECT_SENSOR][libCon.ID])))
        .map(k => [k, setOfObjects[k][locCon.AT_OBJECT_SENSOR][libCon.FIELDS][libCon.ATF_SERIAL]]))

    return serials

}

export const getSerialOfSensorByPosition = (positionId) => {
    let setOfObjects = store.getState()[locCon.AT_SET_OF_OBJECTS]

    if (positionId in setOfObjects
        && locCon.AT_OBJECT_SENSOR in setOfObjects[positionId]
        && libCon.FIELDS in setOfObjects[positionId][locCon.AT_OBJECT_SENSOR]
        && !isNullOrUndefined(setOfObjects[positionId][locCon.AT_OBJECT_SENSOR][libCon.FIELDS][libCon.ATF_SERIAL])) {

        return setOfObjects[positionId][locCon.AT_OBJECT_SENSOR][libCon.FIELDS][libCon.ATF_SERIAL]

    }


    return null

}


export const RefHasSensorByBrand = (sensorBrand) => {

    const bundleDevices = RefBundleDevices()
    const [hasSensors, setHasSensors] = useState(() => false)


    useEffect(() => {

        const newHasSensor = bundleDevices.some(sens => sens[libCon.FIELDS][libCon.ATF_BRAND] === sensorBrand)
        setHasSensors(newHasSensor)


    }, [bundleDevices, sensorBrand])

    return (hasSensors)

}

export const hasSensorByBrand = (sensorBrand) => {

    const setOfObjects = store.getState()[locCon.AT_SET_OF_OBJECTS]

    const hasSensor = Object.values(setOfObjects).map(ob => ob[locCon.AT_OBJECT_SENSOR]).some(sens => sens[libCon.FIELDS][libCon.ATF_BRAND] === sensorBrand)

    return hasSensor

}



export const RefHasSensorByBrandAndModel = (sensorBrand, sensorModel) => {

    const bundleDevices = RefBundleDevices()
    const [hasSensors, setHasSensors] = useState(() => false)


    useEffect(() => {

        const newHasSensor = bundleDevices.some(sens => sens[libCon.FIELDS][libCon.ATF_BRAND] === sensorBrand && sens[libCon.FIELDS][libCon.ATF_MODEL] === sensorModel)
        setHasSensors(newHasSensor)


    }, [bundleDevices, sensorBrand, sensorModel])

    return (hasSensors)

}

export const RefIsSensorByBrand = (positionId, sensorBrand) => {

    const sensor = RefATObjectInsideSetFields(positionId, locCon.AT_OBJECT_SENSOR)

    const [isValue, setIsValue] = useState(false)

    useEffect(() => {

        if (!isNullOrUndefined(sensor))
            setIsValue(sensor[libCon.ATF_BRAND] === sensorBrand)

    }, [sensor, sensorBrand])

    return isValue


}

export const RefIsSensorByBrandAndModel = (positionId, sensorBrand, sensorModel) => {

    const sensor = RefATObjectInsideSetFields(positionId, locCon.AT_OBJECT_SENSOR)

    const [isValue, setIsValue] = useState(false)

    useEffect(() => {

        if (!isNullOrUndefined(sensor))
            setIsValue(sensor[libCon.ATF_BRAND] === sensorBrand && sensor[libCon.ATF_MODEL] === sensorModel)

    }, [sensor, sensorBrand, sensorModel])

    return isValue


}

export const RefRefSensorByBrandPositionIds = (sensorBrand) => {

    const keys = RefKeysOfATOSet()
    const [sensorKeys, setSensorKeys] = useState([])

    useEffect(() => {

        const atoSet = store.getState()[locCon.AT_SET_OF_OBJECTS]

        setSensorKeys(keys.filter(k => locCon.AT_OBJECT_SENSOR in atoSet[k]
            && libCon.FIELDS in atoSet[k][locCon.AT_OBJECT_SENSOR]
            && libCon.ATF_BRAND in atoSet[k][locCon.AT_OBJECT_SENSOR][libCon.FIELDS]
            && atoSet[k][locCon.AT_OBJECT_SENSOR][libCon.FIELDS][libCon.ATF_BRAND] === sensorBrand))

    }, [keys, sensorBrand])


    return sensorKeys

}


export const RefSensorByBrandAndModelPositionIds = (sensorBrand, sensorModel) => {

    const keys = RefKeysOfATOSet()
    const [sensorKeys, setSensorKeys] = useState([])

    useEffect(() => {

        const atoSet = store.getState()[locCon.AT_SET_OF_OBJECTS]

        setSensorKeys(keys.filter(k => locCon.AT_OBJECT_SENSOR in atoSet[k]
            && libCon.FIELDS in atoSet[k][locCon.AT_OBJECT_SENSOR]
            && libCon.ATF_BRAND in atoSet[k][locCon.AT_OBJECT_SENSOR][libCon.FIELDS]
            && atoSet[k][locCon.AT_OBJECT_SENSOR][libCon.FIELDS][libCon.ATF_BRAND] === sensorBrand
            && libCon.ATF_MODEL in atoSet[k][locCon.AT_OBJECT_SENSOR][libCon.FIELDS]
            && atoSet[k][locCon.AT_OBJECT_SENSOR][libCon.FIELDS][libCon.ATF_MODEL] === sensorModel))

    }, [keys, sensorBrand, sensorModel])


    return sensorKeys

}

export const hasGovees = () => {
    return hasSensorByBrand(libCon.GOVEE)
}

export const RefHasGovees = () => {
    const hasSensors = RefHasSensorByBrand(libCon.GOVEE)
    return (hasSensors)
}


export const IsGovee = (positionId) => {
    const isValue = RefIsSensorByBrand(positionId, libCon.GOVEE)
    return isValue
}

export const RefGoovePositionIds = () => {
    const positionIds = RefRefSensorByBrandPositionIds(libCon.GOVEE)
    return positionIds
}



export const hasKestrels = () => {
    return hasSensorByBrand(libCon.KESTREL)
}


export const RefHasKestrels = () => {
    const hasSensors = RefHasSensorByBrand(libCon.KESTREL)
    return (hasSensors)
}


export const RefIsKestrel = (positionId) => {
    const isValue = RefIsSensorByBrand(positionId, libCon.KESTREL)
    return isValue
}

export const RefKestrelPositionIds = () => {
    const positionIds = RefRefSensorByBrandPositionIds(libCon.KESTREL)
    return positionIds
}


export const RefHasHobos = () => {
    const hasSensors = RefHasSensorByBrand(libCon.HOBO)
    return hasSensors
}


export const RefIsHobos = (positionId) => {
    const isValue = RefIsSensorByBrand(positionId, libCon.HOBO)
    return isValue
}

export const RefHobosPositionIds = () => {
    const positionIds = RefRefSensorByBrandPositionIds(libCon.HOBO)
    return positionIds
}


export const RefHasMXHobo = () => {
    const hasSensors = RefHasSensorByBrandAndModel(libCon.HOBO, libCon.MX_HOBO_MODEL)
    return hasSensors

}

export const RefMXHobosPositionIds = () => {
    const positionIds = RefSensorByBrandAndModelPositionIds(libCon.HOBO, libCon.MX_HOBO_MODEL)
    return positionIds
}


export const refreshSensor = async (positionId) => {

    let sensorSerial = store.getState()[locCon.AT_SET_OF_OBJECTS][positionId][locCon.AT_OBJECT_SENSOR][libCon.FIELDS][libCon.ATF_SERIAL]


    let [sensorResponnse, sensor] = await getSensorBySerial(sensorSerial)

    if (sensorResponnse === libCon.OK && Object.keys(sensor).length > 0) {
        let sensorId = Object.keys(sensor)[0]
        setATObjectInsideSet(positionId, locCon.AT_OBJECT_SENSOR, sensor[sensorId][libCon.ID], sensor[sensorId]);
    }


    let [response, sensorFiles] = await getReceivedSensorFilesBySerial(sensorSerial)

    if (response === libCon.OK) {
        let payload = { [libCon.KEY]: sensorSerial, [libCon.VALUE]: Object.values(sensorFiles) }

        store.dispatch({
            type: types.UPDATE_RECEIVED_FILES_FOR_SENSOR,
            payload: payload
        })

    }

    return response


}

export const RefReceivedSensorFiles = (positionId) => {


    const serial = useSelector(state => state[locCon.AT_SET_OF_OBJECTS][positionId][locCon.AT_OBJECT_SENSOR][libCon.FIELDS][libCon.ATF_SERIAL])
    const fileReference = useSelector(state => isNullOrUndefined(serial) ? null : state[locCon.AT_RECEIVED_SENSOR_FILES][serial])


    const [files, setFiles] = useState([])

    useEffect(() => {

        if (!isNullOrUndefined(fileReference))
            setFiles(fileReference)
        else
            setFiles([])


    }, [serial, fileReference])


    return files

}


export const RefSensorStatus = (positionId) => {

    const [status, setStatus] = useState(libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT)
    const [message, setMessage] = useState(libCon.MESSAGE_INCOMPLETE_DEPLOYMENT)

    // Information from sensor
    const latestFiles = RefReceivedSensorFiles(positionId)
    const model = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSOR, libCon.ATF_MODEL)
    const brand = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSOR, libCon.ATF_BRAND)
    const lastBatteryChange = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSOR, libCon.ATF_LATEST_BATTERY_CHANGE)
    const sensorRecordCreated = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSOR, libCon.ATF_DATE_CREATED)
    const placementStart = RefATOInsideSetField(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_START_DATE)

    useEffect(() => {

        let finalLastBatteryChange = lastBatteryChange
        if (isNullOrUndefined(lastBatteryChange))
            finalLastBatteryChange = sensorRecordCreated

        let latestHouseVisit = new Date()
        if (!isNullOrUndefined(latestFiles) &&
            !isNullOrUndefined(model) &&
            !isNullOrUndefined(brand) &&
            !isNullOrUndefined(finalLastBatteryChange) &&
            !isNullOrUndefined(placementStart)) {
            let [newStatus, newMessage] = getSensorPlacementStatus(brand, model, latestFiles, latestHouseVisit, new Date(placementStart), new Date(finalLastBatteryChange))

            setStatus(newStatus)
            setMessage(newMessage)

        }


    }, [
        latestFiles,
        model,
        brand,
        lastBatteryChange,
        placementStart,
        sensorRecordCreated
    ])


    return [status, message]


}

export const RefPhoneStatus = () => {


    const [status, setStatus] = useState(libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT)
    const [message, setMessage] = useState(libCon.MESSAGE_INCOMPLETE_DEPLOYMENT)

    // Information from sensor
    const latestUpload = RefATOField(locCon.AT_OBJECT_PHONE, libCon.ATF_LAST_UPLOADED)

    useEffect(() => {

        let latestHouseVisit = new Date()
        if (!isNullOrUndefined(latestUpload)) {
            let [newStatus, newMessage] = getPhonePlacementStatus(new Date(latestUpload), latestHouseVisit)

            setStatus(newStatus)
            setMessage(newMessage)

        }


    }, [latestUpload])


    return [status, message]

}


export const refreshPhone = async () => {

    let phoneSerial = store.getState()[locCon.AT_OBJECT_PHONE][libCon.FIELDS][libCon.ATF_SERIAL]
    let phoneId = store.getState()[locCon.AT_OBJECT_PHONE][libCon.ID]


    let [response, phones] = await getPhoneBySerial(phoneSerial)


    if (response === libCon.OK) {

        if (phoneId in phones)
            setATObject(locCon.AT_OBJECT_PHONE, phoneId, phones[phoneId])
        else
            response = libCon.NOT_FOUND_ERROR

    }

    return response


}




export const RefWearableStatus = () => {


    const [status, setStatus] = useState(libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT)
    const [message, setMessage] = useState(libCon.MESSAGE_INCOMPLETE_DEPLOYMENT)

    // Information from sensor
    const latestUpload = RefATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_LAST_UPLOADED)
    const latestHeartRateTimestamp = RefATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_LATEST_HEART_RATE_UPLOAD)


    useEffect(() => {

        let latestHouseVisit = new Date()
        if (!isNullOrUndefined(latestUpload)) {
            let [newStatus, newMessage] = getWearablePlacementStatus(new Date(latestUpload), isNullOrUndefined(latestHeartRateTimestamp) ? null : new Date(latestHeartRateTimestamp), latestHouseVisit)

            setStatus(newStatus)
            setMessage(newMessage)

        }


    }, [latestUpload, latestHeartRateTimestamp])


    return [status, message]

}



export const refreshWearableStatus = async () => {

    let accountId = store.getState()[locCon.AT_OBJECT_EMAIL_ACCOUNT][libCon.ID]
    let requestId = store.getState()[locCon.AT_OBJECT_EMAIL_ACCOUNT][libCon.FIELDS][libCon.ATF_FITBIT_REQUEST_ID]
    let token = store.getState()[locCon.AT_OBJECT_EMAIL_ACCOUNT][libCon.FIELDS][libCon.ATF_FITBIT_TOKEN]
    let email = store.getState()[locCon.AT_OBJECT_EMAIL_ACCOUNT][libCon.FIELDS][libCon.ATF_EMAIL]


    let [fitbitResponse, lastSynched] = await getLastSync(requestId, token)


    if (fitbitResponse !== libCon.OK)
        return fitbitResponse



    // Checks HeartRate 
    let [fitbitHeartRateResponse, recentHeartRate] = await getLatestHeartRateTimestamp(requestId, token, lastSynched)


    if (fitbitHeartRateResponse !== libCon.OK)
        return fitbitResponse

    let [response, accounts] = await updateAccountLastSynched(email, lastSynched, recentHeartRate)

    if (response === libCon.OK) {
        if (accountId in accounts)
            setATObject(locCon.AT_OBJECT_EMAIL_ACCOUNT, accountId, accounts[accountId])
        else
            response = libCon.NOT_FOUND_ERROR
    }

    return response





}


export const refreshWearableStatusByCoverage = async () => {


    let email = store.getState()[locCon.AT_OBJECT_EMAIL_ACCOUNT][libCon.FIELDS][libCon.ATF_EMAIL]

    let [response, newEmailRecords] = await getUpdatedWearableCoverage(email)

    if (response !== libCon.OK)
        return response

    let emailId = Object.keys(newEmailRecords)[0]

    setATObject(locCon.AT_OBJECT_EMAIL_ACCOUNT, emailId, newEmailRecords[emailId])



    return response


}



export const RefIntakeFormStatus = (intakeStatus, lastChecked, extraQuestions) => {




    const [status, setStatus] = useState(libCon.DEVICE_STATUS_NEEDS_REFRESH)
    const [message, setMessage] = useState(libCon.MESSAGE_PLEASE_REFRESH)

    useEffect(() => {

        let [newStatus, newMessage] = getIntakeFormStatus(intakeStatus, lastChecked, extraQuestions)

        setStatus(newStatus)
        setMessage(newMessage)


    }, [intakeStatus, lastChecked, extraQuestions])


    return [status, message]

}

export const RefParticipantIntakeFormStatus = () => {

    // Participant Intake Form Status
    const intakeStatus = RefATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_INTAKE_FORM_STATUS)
    const lastChecked = RefStoredNested(locCon.FORMS_LAST_CHECKED, libCon.PARTICIPANT_INTAKE_FORM)
    const extraQuestions = RefStoredNested(locCon.PENDING_FORM_QUESTIONS_OBJECT, libCon.PARTICIPANT_INTAKE_FORM)

    return RefIntakeFormStatus(intakeStatus, lastChecked, extraQuestions)
}

export const RefHouseIntakeFormStatus = () => {

    // House Intake Form Status
    const intakeStatus = RefATOField(locCon.AT_OBJECT_HOUSE, libCon.ATF_INTAKE_FORM_STATUS)
    const lastChecked = RefStoredNested(locCon.FORMS_LAST_CHECKED, libCon.HOUSE_INTAKE_FORM)
    const extraQuestions = RefStoredNested(locCon.PENDING_FORM_QUESTIONS_OBJECT, libCon.HOUSE_INTAKE_FORM)

    return RefIntakeFormStatus(intakeStatus, lastChecked, extraQuestions)
}


export const RefPercelptualSurveyStatus = () => {


    const [status, setStatus] = useState(libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT)
    const [message, setMessage] = useState(libCon.MESSAGE_INCOMPLETE_DEPLOYMENT)

    // Information from participant
    const lastUpload = RefATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_LAST_UPLOADED_PERCEPTUAL_SURVEY)

    useEffect(() => {


        let latestHouseVisit = new Date()
        let dateLastUpload = null
        if (!isNullOrUndefined(lastUpload))
            dateLastUpload = new Date(lastUpload)

        let [newStatus, newMessage] = getPerceptualSurveyStatus(dateLastUpload, latestHouseVisit)

        setStatus(newStatus)
        setMessage(newMessage)


    }, [lastUpload])


    return [status, message]

}


export const refreshParticipant = async () => {

    let internalId = store.getState()[locCon.AT_OBJECT_PARTICIPANT][libCon.ID]


    let [response, participants] = await getParticipant(internalId)


    if (response === libCon.OK) {

        if (internalId in participants)
            setATObject(locCon.AT_OBJECT_PARTICIPANT, internalId, participants[internalId])
        else
            response = libCon.NOT_FOUND_ERROR

    }

    return response


}

export const refreshHouse = async () => {

    let internalId = store.getState()[locCon.AT_OBJECT_HOUSE][libCon.ID]

    let [response, houses] = await getHouse(internalId)

    if (response === libCon.OK) {

        if (internalId in houses)
            setATObject(locCon.AT_OBJECT_HOUSE, internalId, houses[internalId])
        else
            response = libCon.NOT_FOUND_ERROR
    }

    return response

}



export const resetATObject = (atoId) => {
    let payload = { [libCon.KEY]: atoId }

    store.dispatch({
        type: types.RESET_AT_OBJECT,
        payload: payload
    })
}


export const resetATObjectInsideSet = (positionId, atoId) => {
    let payload = { [libCon.KEY]: atoId, [libCon.POSITION_ID]: positionId }


    store.dispatch({
        type: types.RESET_AT_OBJECT_INSIDE_SET,
        payload: payload
    })
}

export const DeviceStatusReducerHook = () => {

    const initialStatus = {

        [locCon.AT_OBJECT_EMAIL_ACCOUNT]: libCon.STATUS_NOT_STARTED,
        [locCon.AT_OBJECT_WEARABLE]: libCon.STATUS_NOT_STARTED,
        [locCon.AT_OBJECT_BUNDLE]: libCon.STATUS_NOT_STARTED,
        [locCon.AT_OBJECT_SENSOR]: libCon.STATUS_NOT_STARTED,
        [locCon.AT_OBJECT_PHONE]: libCon.STATUS_NOT_STARTED,


        [locCon.AT_OBJECT_HOUSE]: libCon.STATUS_NOT_STARTED,
        [locCon.AT_OBJECT_PARTICIPANT]: libCon.STATUS_NOT_STARTED,

        // Placements
        [locCon.AT_OBJECT_PARTICIPANT_PLACEMENT]: libCon.STATUS_NOT_STARTED,
        [locCon.AT_OBJECT_WEARABLE_PLACEMENT]: libCon.STATUS_NOT_STARTED,
        [locCon.AT_OBJECT_PHONE_PLACEMENT]: libCon.STATUS_NOT_STARTED,
        [locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT]: libCon.STATUS_NOT_STARTED,

    }

    // Actions
    const UPDATE_ALL = "UPDATE_ALL"
    const UPDATE_SINGLE = "UPDATE_SINGLE"



    const reducer = (state = initialStatus, action) => {

        let key, val, newState
        switch (action.type) {
            case UPDATE_ALL:
                val = action.payload[libCon.VALUE]

                newState = { ...state }
                Object.keys(state).forEach(k => newState[k] = val)

                state = newState

                break;

            case UPDATE_SINGLE:
                val = action.payload[libCon.VALUE]
                key = action.payload[libCon.KEY]

                state = { ...state, [key]: val }
                break


            default:
                break;
        }


        return state



    }


    // Status of thte bundles
    const [objectStatus, dispatch] = useReducer(reducer, initialStatus)

    const updateAll = (val) => { dispatch({ type: UPDATE_ALL, payload: { [libCon.VALUE]: val } }) }
    const updateSingle = (key, val, localStatus = {}) => {
        localStatus[key] = val
        dispatch({ type: UPDATE_SINGLE, payload: { [libCon.KEY]: key, [libCon.VALUE]: val } })
    }



    return [objectStatus, updateAll, updateSingle]
}

export const RefPositionIdMissingPlacement = () => {

    const keys = RefKeysOfATOSet()

    const [positionId, setPositionId] = useState(null)

    useEffect(() => {


        let allSets = store.getState()[locCon.AT_SET_OF_OBJECTS]

        let newPositionId = keys.find(k => k in allSets && isNullOrUndefined(allSets[k][locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT][libCon.ID]))

        if (isNullOrUndefined(newPositionId))
            setPositionId(null)
        else
            setPositionId(newPositionId)


    }, [keys])

    return positionId

}


export const isPlacementActiveInsideSet = (positionId, atoId) => {

    let allSets = store.getState()[locCon.AT_SET_OF_OBJECTS]

    if (positionId in allSets
        && atoId in allSets[positionId]
        && libCon.ID in allSets[positionId][atoId]
        && !isNullOrUndefined(allSets[positionId][atoId][libCon.ID])
        && libCon.FIELDS in allSets[positionId][atoId]
        && isNullOrUndefined(allSets[positionId][atoId][libCon.FIELDS][libCon.ATF_END_DATE]))
        return true

    return false


}

export const isSensorPlacementActive = (positionId) => {
    return isPlacementActiveInsideSet(positionId, locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT)
}



export const RefIssuesStatus = () => {


    const [status, setStatus] = useState(libCon.DEVICE_STATUS_NEEDS_REFRESH)
    const [message, setMessage] = useState(libCon.MESSAGE_PLEASE_REFRESH)

    const issues = RefStoredValue(locCon.ISSUES_OBJECT)
    const lastIssueCheck = RefStoredValue(locCon.LAST_ISSUE_CHECK)

    useEffect(() => {

        let [newStatus, newMessage] = getIssuesStatus(issues, lastIssueCheck)

        setStatus(newStatus)
        setMessage(newMessage)

    }, [issues, lastIssueCheck])


    return [status, message]

}


export const RefPendingIssues = () => {
    const issues = RefStoredValue(locCon.ISSUES_OBJECT)

    const [pendingIssues, setPendingIssues] = useState({})

    useEffect(() => {
        let newPendingIssues = filterObjectByFunction(issues, i => i[libCon.ATF_RESPONSIBLE] === libCon.ATF_GROUND_TEAM && i[libCon.ATF_STATUS] === libCon.ATF_PENDING)
        setPendingIssues(newPendingIssues)

    }, [issues])

    return (pendingIssues)



}

export const getPendingIssues = () => {
    let issues = getStoredValue(locCon.ISSUES_OBJECT)
    issues = filterObjectByFunction(issues, i => i[libCon.ATF_RESPONSIBLE] === libCon.ATF_GROUND_TEAM && i[libCon.ATF_STATUS] === libCon.ATF_PENDING)

    return issues
}

export const updateIssue = (id, issue) => {
    let issues = getStoredValue(locCon.ISSUES_OBJECT)
    setStoredValue(locCon.ISSUES_OBJECT, { ...issues, [id]: { ...issue } })
}

export const refreshIssues = async () => {

    let participantId = store.getState()[locCon.AT_OBJECT_PARTICIPANT][libCon.ID]

    let [response, participants] = await getParticipant(participantId)



    if (response !== libCon.OK)
        return libCon.NOT_FOUND_ERROR

    let fields = participants[participantId]
    setATObject(locCon.AT_OBJECT_PARTICIPANT, participantId, fields)

    let issuesIds = []
    if (libCon.ATF_ISSUES in fields)
        issuesIds = fields[libCon.ATF_ISSUES]

    if (issuesIds.length === 0) {
        setStoredValue(locCon.ISSUES_OBJECT, [])
        setStoredValue(locCon.LAST_ISSUE_CHECK, nowISO())
        return libCon.OK
    }

    let [issuesResponse, issues] = await getIssuesByIds(issuesIds)

    if (issuesResponse !== libCon.OK)
        return libCon.NOT_FOUND_ERROR

    setStoredValue(locCon.ISSUES_OBJECT, issues)
    setStoredValue(locCon.LAST_ISSUE_CHECK, nowISO())

    return libCon.OK

}

export const RefExtraRequestStatus = () => {


    const [status, setStatus] = useState(libCon.DEVICE_STATUS_NEEDS_REFRESH)
    const [message, setMessage] = useState(libCon.MESSAGE_PLEASE_REFRESH)

    const pendingItems = RefStoredValue(locCon.EXTRA_REQUEST_PENDING_ITEMS)
    const lastExtraRequestCheck = RefStoredValue(locCon.LAST_EXTRA_REQUEST_CHECK)

    useEffect(() => {

        let [newStatus, newMessage] = getExtraRequestStatus(pendingItems, lastExtraRequestCheck)

        setStatus(newStatus)
        setMessage(newMessage)

    }, [pendingItems, lastExtraRequestCheck])


    return [status, message]

}


export const refreshParticipantIntakeForm = async () => {

    let participantId = store.getState()[locCon.AT_OBJECT_PARTICIPANT][libCon.ID]

    let [response, participants] = await getParticipant(participantId)



    if (response !== libCon.OK)
        return libCon.NOT_FOUND_ERROR

    let fields = participants[participantId]
    setATObject(locCon.AT_OBJECT_PARTICIPANT, participantId, fields)

    let questionsIds = []
    if (libCon.ATF_PENDING_QUESTIONS_IF in fields)
        questionsIds = fields[libCon.ATF_PENDING_QUESTIONS_IF]

    if (questionsIds.length === 0) {
        setStoredNestedValue(locCon.PENDING_FORM_QUESTIONS_OBJECT, libCon.PARTICIPANT_INTAKE_FORM, {})
        setStoredNestedValue(locCon.FORMS_LAST_CHECKED, libCon.PARTICIPANT_INTAKE_FORM, nowISO())
        return libCon.OK
    }

    let [questionsResponse, questions] = await getPendingQuestionsParticipantIntakeForm(questionsIds)

    if (questionsResponse !== libCon.OK)
        return libCon.NOT_FOUND_ERROR


    setStoredNestedValue(locCon.PENDING_FORM_QUESTIONS_OBJECT, libCon.PARTICIPANT_INTAKE_FORM, questions)
    setStoredNestedValue(locCon.FORMS_LAST_CHECKED, libCon.PARTICIPANT_INTAKE_FORM, nowISO())

    return libCon.OK

}



export const refreshHouseIntakeForm = async () => {

    let houseId = store.getState()[locCon.AT_OBJECT_HOUSE][libCon.ID]

    let [response, houses] = await getParticipant(houseId)



    if (response !== libCon.OK)
        return libCon.NOT_FOUND_ERROR

    let fields = houses[houseId]
    setATObject(locCon.AT_OBJECT_HOUSE, houseId, fields)

    let questionsIds = []
    if (libCon.ATF_PENDING_QUESTIONS_IF in fields)
        questionsIds = fields[libCon.ATF_PENDING_QUESTIONS_IF]

    if (questionsIds.length === 0) {
        setStoredNestedValue(locCon.PENDING_FORM_QUESTIONS_OBJECT, libCon.HOUSE_INTAKE_FORM, {})
        setStoredNestedValue(locCon.FORMS_LAST_CHECKED, libCon.HOUSE_INTAKE_FORM, nowISO())
        return libCon.OK
    }

    let [questionsResponse, questions] = await getPendingQuestionsHouseIntakeForm(questionsIds)

    if (questionsResponse !== libCon.OK)
        return libCon.NOT_FOUND_ERROR


    setStoredNestedValue(locCon.PENDING_FORM_QUESTIONS_OBJECT, libCon.HOUSE_INTAKE_FORM, questions)
    setStoredNestedValue(locCon.FORMS_LAST_CHECKED, libCon.HOUSE_INTAKE_FORM, nowISO())

    return libCon.OK

}

