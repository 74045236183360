import React, { useState } from 'react'
import * as locCon from '../LocalConstants';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import * as libCon from '../community-hats-js-library/Constants';
import CollectionStatus from '../elements/CollectionStatus';
import { SimpleCopyField, SimpleCopyFieldFromATO } from '../elements/SimpleStoreCopyField';
import { refreshWearableStatus, refreshWearableStatusByCoverage, RefWearableStatus } from '../hooks/BundleDeviceHooks';
import { showErrorDialogByCode } from '../utils/dialogFunctions';
import { showError, showNotification } from '../utils/generalFunctions';
import CustomSpin from '../elements/CustomSpin';
import { CustomPrimaryButtonWithDisability } from '../elements/CustomButton';
import { RefATObjectFields, RefATOField } from '../hooks/StoreHooks';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { formatDistanceShort, formatToIndiaTimeReadable } from '../community-hats-js-library/utils/dateFunctions';
import WearableCoverageViewer from '../community-hats-js-library/components/WearableCoverageViewer';


function WearableStatusViewer({ includeTitle = true }) {
    const i18n = RefLanguage()


    const [status, message] = RefWearableStatus()

    const email = RefATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_EMAIL)
    const serial = RefATOField(locCon.AT_OBJECT_WEARABLE, libCon.ATF_SERIAL)
    const participantObject = RefATObjectFields(locCon.AT_OBJECT_PARTICIPANT)
    const emailObject = RefATObjectFields(locCon.AT_OBJECT_EMAIL_ACCOUNT)


    const lastUploaded = RefATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_LAST_UPLOADED)
    const lastHeartRate = RefATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_LATEST_HEART_RATE_UPLOAD)


    const [isSynching, setisSynching] = useState(false)


    const refreshRecords = async () => {
        setisSynching(true)

        let response = await refreshWearableStatus()

        if (response !== libCon.OK)
            showErrorDialogByCode(response)
        else
            showNotification(i18n.t("statusUpdatedCorrectly"))


        // Only calls coverage if email is active
        if (!isNullOrUndefined(emailObject) && !isNullOrUndefined(emailObject[libCon.ATF_ACTIVE]) && emailObject[libCon.ATF_ACTIVE] === libCon.YES) {
            let coverageResponse = await refreshWearableStatusByCoverage()

            if (coverageResponse !== libCon.OK)
                showError(i18n.t("coverageServiceNotAvailable"))
            else
                showNotification(i18n.t("coverageUpdatedCorrectly"))
        }

        setisSynching(false)
    }


    return (
        <div className='verticalSection'>
            {includeTitle ? <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("fitbitDevice")}</CustomText> : <div></div>}

            <CollectionStatus status={status} message={message} />

            <SimpleCopyFieldFromATO title={i18n.t("email")} atoId={locCon.AT_OBJECT_EMAIL_ACCOUNT} fieldId={libCon.ATF_EMAIL} />
            <SimpleCopyFieldFromATO title={i18n.t("fitbitSerial")} atoId={locCon.AT_OBJECT_WEARABLE} fieldId={libCon.ATF_SERIAL} />

            <div className='horizontalSection'>
                <div style={{ width: "50%" }}>
                    <SimpleCopyField title={i18n.t("lastSynched")} value={isNullOrUndefined(lastUploaded) ? i18n.t("never") : <>{`${formatToIndiaTimeReadable(lastUploaded, true, false)}`}<br />{`(${formatDistanceShort(lastUploaded)})`}</>} enableCopy={false} />
                </div>
                <div style={{ width: "50%" }}>
                    <SimpleCopyField title={<div>{i18n.t("latestHeartRateUpload")}</div>} value={isNullOrUndefined(lastHeartRate) ? i18n.t("never") : <>{`${formatToIndiaTimeReadable(lastHeartRate, true, false)}`}<br />{`(${formatDistanceShort(lastHeartRate)})`}</>} enableCopy={false} />
                </div>
            </div>
            {
                isSynching
                    ? <CustomSpin type={locCon.BUTTON_SPINNER} />
                    : <CustomPrimaryButtonWithDisability isDisabled={isNullOrUndefined(email) || isNullOrUndefined(serial)} title={i18n.t("incompleteDeployment")} description={i18n.t("addEmailAndWearable")} onClick={() => refreshRecords()}>{i18n.t("refresh")}</CustomPrimaryButtonWithDisability>
            }
            <div className='verticalSection' style={{ backgroundColor: 'var(--primary-color-5)', marginBottom: "1vh", borderRadius: "10px" }}>
                <WearableCoverageViewer participant={participantObject}
                    email={emailObject}
                    updatingEmail={isSynching}
                    translations={(k) => i18n.t(k)}
                    externalDisabledMessage={isNullOrUndefined(emailObject) || isNullOrUndefined(emailObject[libCon.ATF_ACTIVE]) || emailObject[libCon.ATF_ACTIVE] === libCon.NO
                        ? i18n.t("emailNotDeployedYet")
                        : status !== libCon.OK && status !== libCon.DEVICE_STATUS_OK
                            ? i18n.t("makeSureDataUpToDate") : null} />
            </div>



        </div >
    )
}

export default WearableStatusViewer