import React, { useEffect, useState } from 'react'
import * as libCon from "../community-hats-js-library/Constants"
import { SimpleCopyField } from '../elements/SimpleStoreCopyField'
import { RefLanguage } from '../hooks/LanguageHooks'

const LOADING = "----"

function SensorInboxEmail() {

    const i18n = RefLanguage()

    const [email, setEmail] = useState(LOADING)

    useEffect(() => {

        const getTheEmail = async () => {
            setEmail(await libCon.CONFIG(libCon.SENSOR_INBOX_EMAIL))
        }

        getTheEmail()

    }, [])

    return (
        <div>
            <SimpleCopyField title={i18n.t("sensorInboxOptions")} value={email} />
            <SimpleCopyField value={email === LOADING ? LOADING : email.replace("@", "1@")} />
            <SimpleCopyField value={email === LOADING ? LOADING : email.replace("@", "2@")} />
            <SimpleCopyField value={email === LOADING ? LOADING : email.replace("@", "3@")} />

        </div>

    )
}

export default SensorInboxEmail