import React from 'react'
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
import BackButton from '../elements/BackButton';
import { LanguageState } from '../hooks/LanguageHooks';
import { sleep } from '../community-hats-js-library/utils/generalFunctions';
import { setStoredValue } from '../hooks/StoreHooks';
import { Affix, Typography } from 'antd';
import CustomButton from '../elements/CustomButton';
import CustomText from '../elements/CustomText';


const { Text } = Typography;


function Header({ long = true, includeBackButton = true, includeUpdateDelay = false, hideTitle = false }) {

  // Translation
  const [i18n, currentLocale, setCurrentLocale] = LanguageState()

  const localSetCurrentLocale = async (loc) => {
    if (includeUpdateDelay) {

      setStoredValue(locCon.UPDATING_LANGUAGE, true)
      await sleep(200)

      setCurrentLocale(loc)

      setTimeout(() => setStoredValue(locCon.UPDATING_LANGUAGE, false), locCon.LANGUAGE_LOADING_TIME);


    }
    else
      setCurrentLocale(loc)
  }

  return (
    <div className='verticalSection' style={{ paddingTop: "2vw", paddingLeft: "10vw", paddingRight: "10vw" }}>
      {libCon.DEVELOPMENT ? <Text style={{ color: "white", backgroundColor: "red", width: "100%", textAlign: "center" }}>DEVELOPMENT</Text> : <div></div>}
      {locCon.DEBUG ? <Text style={{ color: "white", backgroundColor: "red", width: "100%", textAlign: "center" }}>DEBUG</Text> : <div></div>}

      <Affix style={{ width: "var(--max-width)" }}>
        <div className='verticalSection' style={{ backgroundColor: "var(--background-color-1-solid)", paddingTop: "1vh", paddingBottom: "1vh" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
            {includeBackButton === true ? <BackButton /> : <div></div>}

            <div style={styles.languagesContainer}>
              <CustomButton type={currentLocale === libCon.EN ? locCon.SELECTED_LANGUAGE_BUTTON : locCon.LANGUAGE_BUTTON} onClick={() => localSetCurrentLocale("en")}>
                EN
              </CustomButton>
              <CustomButton type={currentLocale === libCon.ES ? locCon.SELECTED_LANGUAGE_BUTTON : locCon.LANGUAGE_BUTTON} onClick={() => localSetCurrentLocale("es")}>
                ES
              </CustomButton>
              <CustomButton type={currentLocale === libCon.GU ? locCon.SELECTED_LANGUAGE_BUTTON : locCon.LANGUAGE_BUTTON} onClick={() => localSetCurrentLocale("gu")}>
                ગુ
              </CustomButton>
              <CustomButton type={currentLocale === libCon.HI ? locCon.SELECTED_LANGUAGE_BUTTON : locCon.LANGUAGE_BUTTON} onClick={() => localSetCurrentLocale("hi")}>
                ह
              </CustomButton>
            </div>
          </div>

        </div>
      </Affix>



      <div className="verticalSection" >
        {!hideTitle ? <Text style={styles.mainTitle}>{i18n.t("mainTitle")}</Text> : <div></div>}
        {long === true ? <Text style={styles.subTitle}>{i18n.t("mainSubtitle")}</Text> : <div></div>}
        <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{locCon.VERSION}</CustomText>
      </div>
      <div className='horizontalLine' style={{ marginTop: 0, marginBottom: 5 }} />
    </div>
  )
}

export default Header



const styles = {
  titleContainer: {
    marginBottom: 15,
    backgroundColor: "var(--primary-color1)"
  },
  mainTitle:
  {
    fontSize: 32,
    textAlign: "center",
    color: "var(--primary-color2)",
    fontWeight: 'bold',
    width: "100%"
  },
  subTitle:
  {
    fontSize: 18,
    textAlign: "center",
    color: "#800202",
    width: "100%"
  },
  languagesContainer: {
    display: "flex",
    width: "50%",
    flexDirection: 'row-reverse',
    alignItems: 'stretch',
  },
  selectedLanguage: {
    borderWidth: 1,
    borderColor: "var(--primary-color5)",
    padding: 5,
    marginLeft: 4,
    marginRight: 4,
  },
  language: {
    borderWidth: 1,
    borderColor: "var(--primary-color1)",
    padding: 5,
    marginLeft: 4,
    marginRight: 4
  },
};



