import React, { useEffect, useState } from 'react'
import * as locCon from "../LocalConstants"
import { formatToIndiaTimeReadable } from '../community-hats-js-library/utils/dateFunctions'
import CustomText from './CustomText'

function SimpleClock() {

    const [date, setDate] = useState(new Date())

    useEffect(() => {


        const timer = setInterval(() => setDate(new Date()), 1000 * 60)

        return () => {
            clearInterval(timer)
        }
    })


    return (
        <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{formatToIndiaTimeReadable(date, true, true, false)}</CustomText>
    )
}

export default SimpleClock