import React, { useEffect, useState } from 'react'
import { RefLanguage } from '../hooks/LanguageHooks';
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
// import * as genFun from '../community-hats-js-library/utils/generalFunctions';
import Header from '../components/Header';
import CustomText from '../elements/CustomText';
import CustomButton from '../elements/CustomButton';
import { getATOInsideSetField, getKeysOfATOSet, RefStoredBooleanValue, setMultipleValues, setStoredValue, StateStoredValue } from '../hooks/StoreHooks';
import CheckboxGroup from '../elements/CheckboxGroup';
import { EndAndRemovePlacementInsideSetButton } from '../elements/SyncATOButton';
import { SimpleStoreCopySelectionField } from '../elements/SimpleStoreCopySelectionField';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import CompletionStatusText from '../elements/CompletionStatusText';
import { SensorPlacementViewer } from '../components/SensorPlacementSetViewer';

const initialCheckboxes = [locCon.MS_END_AND_REMOVE_SENSOR_PLACEMENT]

function MoveSensor() {

    const i18n = RefLanguage()



    // Local checkboxes
    const isPlacementRemoved = RefStoredBooleanValue(locCon.MS_END_AND_REMOVE_SENSOR_PLACEMENT)

    const [positionId, setPositionId] = StateStoredValue(locCon.MS_SELECTED_POSITION_ID)

    const [options, setOptions] = useState([])
    const [optionsTextDict, setOptionsTextDict] = useState({})


    const localSetPositionId = (newPositionId) => {

        if (positionId !== newPositionId)
            restartProcess()

        setPositionId(newPositionId)

    }

    useEffect(() => {

        const keys = getKeysOfATOSet()

        let newOptions = [...keys]
        let newOPtionTextDict = Object.fromEntries(keys.map(pId => [pId, getATOInsideSetField(pId, locCon.AT_OBJECT_SENSOR, libCon.ATF_SERIAL)]))
        if (!isNullOrUndefined(positionId) && !newOptions.includes(positionId)) {
            newOptions.push(positionId)
        }

        setOptions(newOptions)
        setOptionsTextDict(newOPtionTextDict)

    }, [positionId])




    const [allCheckboxes, setAllCheckboxes] = useState(initialCheckboxes)

    const restartProcess = () => {

        let newcheckBoxValues = {}

        if (!isNullOrUndefined(positionId)) {
            newcheckBoxValues[locCon.getASHPCheckBoxId(positionId)] = false
            newcheckBoxValues[locCon.getASHPLinkedClickedId(positionId)] = false
        }

        initialCheckboxes.forEach(k => newcheckBoxValues[k] = false)


        setMultipleValues(newcheckBoxValues)
        setPositionId(null)


    }

    useEffect(() => {
        let newCheckBoxes = [...initialCheckboxes]

        if (!isNullOrUndefined(positionId)) {
            newCheckBoxes.push(locCon.getASHPCheckBoxId(positionId))
            newCheckBoxes.push(locCon.getASHPLinkedClickedId(positionId))
        }

        setAllCheckboxes(newCheckBoxes)

    }, [positionId])






    return (
        <div className="mainAppContainer">
            <div className='pageContainer'>

                <Header long={false} />
                <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("moveSensor")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsMoveSensor")}</CustomText>

                <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartProcess")}</CustomButton>

                <div className='horizontalLine' />

                <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("selectSensor")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToSelectSensor")}</CustomText>

                <SimpleStoreCopySelectionField title={i18n.t("sensor")} value={positionId} setValue={localSetPositionId} options={options} optionsTextDict={optionsTextDict} enableCopy={true} />


                <div className='horizontalLine' />

                {/* Remove Sensor placement to Database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("endSensorPalcement")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("endSensorPalcementText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.MS_END_AND_REMOVE_SENSOR_PLACEMENT,
                    [libCon.TEXT]: i18n.t("endSensorPlacement"),
                    [libCon.CONTAINER]:
                        isPlacementRemoved ?
                            <div className='verticalSection'><CustomText type={locCon.SUCCESS_TEXT}>{i18n.t("placementRemovedSuccesfully")}</CustomText></div>
                            : isNullOrUndefined(positionId)
                                ? <div className='verticalSection'><CustomText type={locCon.DANGER_TEXT}>{i18n.t("pleaseSelectASensor")}</CustomText></div>
                                : <EndAndRemovePlacementInsideSetButton positionId={positionId} placementATOid={locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT} onClickCleanup={(val) => { setStoredValue(locCon.MS_END_AND_REMOVE_SENSOR_PLACEMENT, val); setStoredValue(locCon.getASHPCheckBoxId(positionId), !val); setStoredValue(locCon.getASHPLinkedClickedId(positionId), !val) }} forceSuccessText={isPlacementRemoved} />
                }} />
                <div className="horizontalLine" />


                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("deploySensor")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("deploySensorText")}</CustomText>
                <SensorPlacementViewer positionId={positionId} positionNumber={1} />

                <div className="horizontalLine" />


                <CompletionStatusText checkBoxIds={allCheckboxes} />

                <div className="horizontalLine" />

            </div>
        </div>
    );
}

export default MoveSensor